import { pagePadding } from '../../../styles/variables';
import { useGetEmergencyContacts } from '../kontakty.hooks';
import Screen from '@common/components/Screen';
import { call } from '@common/utils/call';
import React from 'react';
import { Linking, StyleSheet, ScrollView } from 'react-native';
import { Text, List } from 'react-native-paper';

function KontaktyScreen() {
  const { emergencyContacts, isEmergencyContactsLoading } =
    useGetEmergencyContacts();

  return (
    <Screen loading={isEmergencyContactsLoading}>
      <ScrollView accessibilityLabel='KontaktyPage'>
        <Text variant='titleMedium' style={styles.gap}>
          Kontakty niezbędne w sytuacjach awaryjnych:
        </Text>
        <List.AccordionGroup>
          {emergencyContacts?.categories.map(
            ({ contacts, title }, categoryIndex) => (
              <List.Accordion
                title={title}
                id={categoryIndex + 1}
                key={`category-${categoryIndex}`}
              >
                {contacts.map(({ name, number }, contactIndex) => (
                  <List.Item
                    accessibilityLabel='KontaktyList'
                    key={`contact-${categoryIndex}-${contactIndex}`}
                    title={name}
                    description={number}
                    onPress={() => call(number.replaceAll(/\s/g, ''))}
                    left={(props) => <List.Icon {...props} icon='phone' />}
                  />
                ))}
              </List.Accordion>
            )
          )}
        </List.AccordionGroup>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  gap: {
    padding: pagePadding,
  },
});

export default KontaktyScreen;
