import { UserSession } from '../services/auth';
import { persistSecurely, saveThemeMode, saveSwipeHint } from './effects';
import { atom } from 'recoil';

export const USER_SESSION_LOADING_STATE = 'loading';

export type UserSessionValue =
  | UserSession
  | typeof USER_SESSION_LOADING_STATE
  | null;

export const userSessionState = atom<UserSessionValue>({
  key: 'userSessionState',
  default: USER_SESSION_LOADING_STATE,
  effects: [persistSecurely('user-session')],
});

export const themeModeState = atom({
  key: 'themeModeState',
  default: 'dark',
  effects: [saveThemeMode],
});

export const swipeHintState = atom({
  key: 'swipeHintState',
  default: 'true',
  effects: [saveSwipeHint],
});
