import { pagePadding } from '../../../styles/variables';
import { format, getDaysLabel, getPeriodLabel } from '../helpers';
import Holiday from '../models/Holiday';
import RequestPayload from '../models/RequestPayload';
import RequestType from '../models/RequestType';
import Picker from './Picker';
import isNotWorkingDay from '@modules/urlopia/utils/isHoliday';
import { useFocusEffect } from '@react-navigation/native';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, HelperText, IconButton, List } from 'react-native-paper';

type NormalTypeProps = {
  holidays: Holiday[];
  remainingVacation: string;
  onSubmit: (payload: RequestPayload) => void;
};

type Range = {
  startDate: Moment;
  endDate: Moment;
};

const NormalType: React.FC<NormalTypeProps> = ({
  holidays,
  remainingVacation,
  onSubmit,
}) => {
  const [picker, setPicker] = useState(false);
  const [counter, setCounter] = useState(0);

  const [range, setRange] = useState<Range | undefined>(undefined);

  useEffect(() => {
    if (!range) return;

    let start = range.startDate.clone();
    let end = range.endDate.clone();
    let count = 0;

    while (start.isSameOrBefore(end)) {
      if (isNotWorkingDay(start, holidays)) {
        start.add(1, 'day');
      } else {
        count += 1;
        start.add(1, 'day');
      }
    }
    setCounter(count);
  }, [range]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setCounter(0);
        setRange(undefined);
      };
    }, [])
  );

  const handlePicker = (date: { startDate: Date; endDate: Date }) => {
    setRange({
      startDate: moment(date.startDate).endOf('day'),
      endDate: moment(date.endDate).endOf('day'),
    });
    setPicker(false);
  };

  const handleSubmit = () => {
    if (range) {
      onSubmit({
        startDate: range.startDate.format(format),
        endDate: range.endDate.format(format),
        type: RequestType.normal,
      });
    }
  };

  const QuickOptions = () => {
    const today = moment().endOf('day');
    const tomorrow = moment().add(1, 'day').endOf('day');

    return (
      <View style={styles.quick}>
        <Button
          mode='text'
          disabled={isNotWorkingDay(today)}
          onPress={() =>
            setRange({
              startDate: today,
              endDate: today,
            })
          }
        >
          Na dziś
        </Button>
        <Button
          mode='text'
          disabled={isNotWorkingDay(tomorrow)}
          onPress={() =>
            setRange({
              startDate: tomorrow,
              endDate: tomorrow,
            })
          }
        >
          Na jutro
        </Button>
      </View>
    );
  };

  return (
    <>
      <List.Section>
        <List.Item
          title={'Termin'}
          description={
            range
              ? getPeriodLabel(range.startDate, range.endDate)
              : 'nie wybrano'
          }
          right={() => (
            <IconButton
              pointerEvents='auto'
              icon='calendar-month'
              onPress={() => setPicker(true)}
            />
          )}
        />
        {range && (
          <List.Item
            title='Liczba dni roboczych'
            description={getDaysLabel(counter)}
          />
        )}
        <QuickOptions />
      </List.Section>
      <Button mode='contained' disabled={!range} onPress={handleSubmit}>
        Złóż wniosek
      </Button>
      <HelperText style={styles.helper} type='info'>
        Pozostały urlop: {remainingVacation}
      </HelperText>
      {picker && (
        <Picker
          holidays={holidays}
          range={range}
          visible={picker}
          onConfirm={handlePicker}
          onDismiss={() => setPicker(false)}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  quick: {
    flexDirection: 'row',
    paddingLeft: 6,
    marginBottom: pagePadding,
  },
  helper: {
    textAlign: 'center',
    marginTop: pagePadding,
  },
});

export default NormalType;
