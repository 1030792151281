import { pagePadding } from '../../../styles/variables';
import History from '../components/History';
import PendingRequests from '../components/PendingRequests';
import { checkAccess, getRemainingVacationTime } from '../helpers';
import Roles from '../models/Roles';
import {
  useGetHistory,
  useGetPendingAcceptances,
  useGetUserDetails,
  useGetVacationDays,
} from '../urlopia.hooks';
import CommonDivider from '@common/components/CommonDivider';
import Screen from '@common/components/Screen';
import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Chip, List, SegmentedButtons } from 'react-native-paper';
import { useIsFetching } from 'react-query';

const RequestScreen = () => {
  const loading = useIsFetching();
  const [selected, select] = useState('history');
  const [expanded, setExpanded] = React.useState(false);

  const { history } = useGetHistory();
  const { userDetails } = useGetUserDetails();
  const { vacationDays } = useGetVacationDays();

  const isLeader = checkAccess(userDetails, Roles.leader);
  const { teams = [] } = userDetails;

  const { acceptances } = useGetPendingAcceptances(isLeader);

  useFocusEffect(
    useCallback(() => {
      select('history');
      return () => {};
    }, [])
  );

  useEffect(() => {
    if (acceptances?.content?.length === 0) {
      select('history');
    }
  }, [acceptances]);

  return (
    <Screen loading={loading}>
      <GestureHandlerRootView>
        <List.Section>
          <List.Item
            title='Pozostały urlop'
            right={() => (
              <Chip mode='outlined' icon='calendar'>
                {getRemainingVacationTime(
                  vacationDays?.remainingDays,
                  vacationDays?.remainingHours
                )}
              </Chip>
            )}
          />
          {teams.length !== 0 && (
            <List.Accordion
              title='Akceptujący'
              expanded={expanded}
              onPress={() => setExpanded(!expanded)}
            >
              {teams.map(({ leader }) => (
                <List.Item key={leader} title={leader} />
              ))}
            </List.Accordion>
          )}
        </List.Section>
        <CommonDivider />

        {isLeader && (
          <SegmentedButtons
            value={selected}
            onValueChange={select}
            style={styles.segment}
            buttons={[
              {
                value: 'history',
                label: 'Moje wnioski',
                style: { flex: 1 },
              },
              {
                icon: acceptances?.content?.length ? 'bell-badge' : '',
                disabled: acceptances?.content?.length === 0,
                value: 'waiting',
                label: 'Do rozpatrzenia',
                style: { flex: 1 },
              },
            ]}
          />
        )}

        {selected === 'history' ? (
          <History history={history && history.content} />
        ) : (
          <PendingRequests requests={acceptances && acceptances.content} />
        )}
      </GestureHandlerRootView>
    </Screen>
  );
};

const styles = StyleSheet.create({
  segment: {
    marginBottom: pagePadding,
  },
});

export default RequestScreen;
