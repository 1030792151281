import Confirmation from './models/Confirmation';
import ConfirmationPayload from './models/ConfirmationPayload';
import {
  IAcceptancesDTO,
  ICalendarDTO,
  IUserDetailsDTO,
  IWorkingHoursDTO,
} from './urlopia.types';
import { call } from '@common/api';
import useApiMutation from '@common/hooks/useApiMutation';
import useApiQuery from '@common/hooks/useApiQuery';
import {
  showSuccessToast,
  showErrorToast,
  ErrorMessage,
} from '@common/services/toast';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { ParamListBase } from '@react-navigation/native';
import { useQueryClient } from 'react-query';

export enum URLOPIA_QUERY_KEYS {
  GET_CALENDAR = 'getCalendar',
  GET_WORKING_HOURS = 'getWorkingHours',
}

export const useGetAbsentUsersAtDate = (selectedDate: string) => {
  const query = useApiQuery({
    queryKey: [URLOPIA_QUERY_KEYS.GET_CALENDAR, selectedDate],
    queryFn: (api) => () =>
      call<ICalendarDTO>(
        api.urlopia.calendar({
          uriParams: { startDate: selectedDate, endDate: selectedDate },
        })
      ),
  });

  const absentUsers = query.data?.calendar?.[selectedDate]?.absentUsers || [];

  absentUsers.sort((a, b) => a.userName.localeCompare(b.userName));

  return {
    ...query,
    absentUsers: absentUsers,
    isLoadingAbsentUsers: query.isLoading,
    placeholderData: [],
    initialData: [],
  };
};

export const useGetWorkingHours = () => {
  const query = useApiQuery({
    queryKey: [URLOPIA_QUERY_KEYS.GET_WORKING_HOURS],
    queryFn: (api) => () => call<IWorkingHoursDTO>(api.urlopia.workingHours()),
  });

  const workingHoursResponse = query.data;
  const [workingHours] = Object.values(workingHoursResponse || {});

  return {
    ...query,
    workingHours,
    isLoadingWorkingHours: query.isLoading,
  };
};

export const useGetHolidays = () => {
  const query = useApiQuery({
    queryKey: ['getHolidays'],
    queryFn: (api) => () => call(api.urlopia.holidays()),
  });

  return {
    ...query,
    holidays: query.data,
    isLoadingHolidays: query.isLoading,
  };
};

export const useGetVacationDays = () => {
  const query = useApiQuery({
    queryKey: ['getVacationDays'],
    queryFn: (api) => () => call(api.urlopia.vacationDays()),
  });

  return {
    ...query,
    vacationDays: query.data,
    isLoadingVacationDays: query.isLoading,
  };
};

export const useGetPendingDays = () => {
  const query = useApiQuery({
    queryKey: ['getPendingDays'],
    queryFn: (api) => () => call(api.urlopia.pendingDays()),
  });

  return {
    ...query,
    pendingDays: query.data,
    isLoadingPendingDays: query.isLoading,
  };
};

export const useAbsenceRequest = (
  navigation: BottomTabNavigationProp<ParamListBase, string, undefined>
) => {
  const queryClient = useQueryClient();

  const mutation = useApiMutation({
    mutationFn: (api) => (payload: any) =>
      call(api.urlopia.absenceRequest({ data: payload })),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries();
        navigation.navigate('RequestScreen');
        showSuccessToast('Wniosek został złożony pomyślnie');
      },
      onError: ({ message }: ErrorMessage) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...mutation,
    absenceRequest: mutation.mutate,
    isAbsenceRequesting: mutation.isLoading,
  };
};

export const useGetUserDetails = () => {
  const query = useApiQuery({
    queryKey: ['getUserDetails'],
    queryFn: (api) => () => call<IUserDetailsDTO>(api.urlopia.userDetails()),
  });

  return {
    ...query,
    userDetails: query.data,
    isLoadingUserDetails: query.isLoading,
  };
};

export const useGetPendingAcceptances = (isLeader: boolean) => {
  const query = useApiQuery({
    queryKey: ['getPendingAcceptances', isLeader],
    queryFn: (api) => () =>
      call<IAcceptancesDTO>(api.urlopia.getPendingAcceptances()),
    enabled: isLeader,
  });

  return {
    ...query,
    acceptances: query.data,
    isLoadingAcceptances: query.isLoading,
  };
};

export const useGetPresenceConfirmations = (userId: number) => {
  const filter = `userId:{id}`;

  const query = useApiQuery({
    queryKey: ['getPresenceConfirmations', userId],
    queryFn: (api) => () =>
      call<Confirmation[]>(
        api.urlopia.presenceConfirmations({ queryParams: { filter } })
      ),
  });

  return {
    ...query,
    confirmations: query.data,
    isLoadingConfirmations: query.isLoading,
  };
};

export const useGetVacations = (userId: number) => {
  const query = useApiQuery({
    queryKey: ['getVacations', userId],
    queryFn: (api) => () =>
      call(api.urlopia.vacations({ uriParams: { userId } })),
  });

  return {
    ...query,
    vacations: query.data,
    isLoadingVacations: query.isLoading,
  };
};

export const useGetHistory = () => {
  const query = useApiQuery({
    queryKey: ['getHistory'],
    queryFn: (api) => () => call(api.urlopia.history()),
  });

  return {
    ...query,
    history: query.data,
    isLoadingHistory: query.isLoading,
  };
};

export const useCancelRequest = () => {
  const queryClient = useQueryClient();

  const mutation = useApiMutation({
    mutationFn:
      (api) =>
      ({ id }: { id: string }) => {
        return call(
          api.urlopia.cancelRequest({
            uriParams: { id },
            data: { status: 'CANCELED' },
          })
        );
      },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries();
        showSuccessToast('Wniosek został pomyślnie anulowany');
      },
      onError: ({ message }: { message: string }) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...mutation,
    cancelRequest: mutation.mutate,
    isCancelingRequest: mutation.isLoading,
  };
};

export const useConfirmPresence = () => {
  const queryClient = useQueryClient();

  const mutation = useApiMutation({
    mutationFn: (api) => (payload: ConfirmationPayload) =>
      call(api.urlopia.presenceConfirmation({ data: payload })),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(['getPresenceConfirmations']);
        showSuccessToast('Pomyślnie zgłoszono obecność');
      },
      onError: ({ message }: { message: string }) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...mutation,
    confirmPresence: mutation.mutate,
    isConfirmingPresence: mutation.isLoading,
  };
};

export const useActionPendingAcceptances = () => {
  const queryClient = useQueryClient();

  const mutation = useApiMutation({
    mutationFn:
      (api) =>
      ({ id, status }: { id: number; status: 'ACCEPTED' | 'REJECTED' }) =>
        call(
          api.urlopia.actionPendingAcceptances({
            uriParams: { id },
            data: { status },
          })
        ),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(['getPendingAcceptances']);
        showSuccessToast('Pomyślnie zmieniono status wniosku');
      },
      onError: ({ message }: { message: string }) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...mutation,
    actionPendingAcceptances: mutation.mutate,
    isActionPendingAcceptances: mutation.isLoading,
  };
};
