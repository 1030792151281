import moment, { Moment } from 'moment';
import 'moment/locale/pl';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';

const DatePicker = ({
  selectedDay,
  next,
  prev,
  updateSelectedDate,
}: {
  selectedDay: Moment;
  next: Function;
  prev: Function;
  updateSelectedDate: Function;
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  return (
    <View style={styles.container}>
      <IconButton borderless icon='chevron-left' onPress={() => prev()} />
      <Text style={styles.label}>
        {moment(selectedDay).format('D MMMM (dddd)')}
      </Text>
      <>
        <IconButton icon='calendar' onPress={() => setOpenDatePicker(true)} />
        <DatePickerModal
          locale='pl'
          mode='single'
          saveLabel='Wybierz'
          visible={openDatePicker}
          onDismiss={() => {
            setOpenDatePicker(false);
          }}
          date={new Date(selectedDay.toISOString())}
          onConfirm={(params) => {
            setOpenDatePicker(false);
            updateSelectedDate(params.date);
          }}
        />
      </>
      <IconButton borderless icon='chevron-right' onPress={() => next()} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 25,
  },
  label: {
    textAlign: 'center',
    minWidth: 165,
  },
});

export default DatePicker;
