import { UserSessionValue } from './atoms';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DefaultValue } from 'recoil';

interface PersistSecurelyParams {
  trigger: 'set' | 'get';
  setSelf: (value: UserSessionValue) => void;
  onSet: (
    param: (
      newValue: UserSessionValue,
      oldValue: UserSessionValue | DefaultValue,
      isReset: boolean
    ) => void
  ) => void;
}

export const persistSecurely =
  (key: string) =>
  ({ trigger, setSelf, onSet }: PersistSecurelyParams) => {
    // If there's a persisted value - set it on load
    const loadPersisted = async () => {
      const savedValue = await AsyncStorage.getItem(key);
      setSelf(savedValue !== null ? JSON.parse(savedValue) : null);
    };

    // Asynchronously set the persisted data
    if (trigger === 'get') {
      loadPersisted();
    }

    // Subscribe to state changes and persist them to localForage
    onSet((newValue, _oldValue, isReset) => {
      isReset
        ? AsyncStorage.removeItem(key)
        : AsyncStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const saveThemeMode = ({ trigger, setSelf, onSet }: any) => {
  const load = async () => {
    const themeMode = await AsyncStorage.getItem('@theme_mode');
    if (themeMode) setSelf(themeMode);
  };

  if (trigger === 'get') {
    load();
  }

  onSet((value: string) => {
    const save = async () => {
      await AsyncStorage.setItem('@theme_mode', value);
    };
    save();
  });
};

export const saveSwipeHint = ({ trigger, setSelf, onSet }: any) => {
  const load = async () => {
    const hint = await AsyncStorage.getItem('@show_swipe_hint');
    if (hint) setSelf(hint);
  };

  if (trigger === 'get') {
    load();
  }

  onSet((value: string) => {
    const save = async () => {
      await AsyncStorage.setItem('@show_swipe_hint', value);
    };
    save();
  });
};
