import {
  ApiConfig,
  EndpointConfig,
  EnvPrefixes,
  ModuleConfig,
} from '../../global.types';
import { HTTPMethod } from '@common/enums/Http';

export type HotdeskApi = {
  getRooms: EndpointConfig;
  getDesks: EndpointConfig;
  bookDesk: EndpointConfig;
  cancelBooking: EndpointConfig;
};

const envPrefixes: EnvPrefixes = {
  development: '/hotdesk-dev/v1',
  production: '/hotdesk/v1',
};

const api: ApiConfig<HotdeskApi> = {
  baseUrl: 'https://apigateway.fingo.info',
  envPrefixes,
  endpoints: {
    getRooms: {
      path: `/rooms`,
      method: HTTPMethod.GET,
    },
    getDesks: {
      path: `/desks?roomId={roomId}&reservationDate={date}`,
      method: HTTPMethod.GET,
    },
    bookDesk: {
      path: `/reservations`,
      method: HTTPMethod.POST,
    },
    cancelBooking: {
      path: `/reservations/{id}`,
      method: HTTPMethod.DELETE,
    },
  },
};

const routingScreens = {
  Hotdesk: {
    path: 'hotdesk',
    screens: {
      HotdeskScreen: '',
    },
  },
};

const config: ModuleConfig = {
  web: true,
  name: 'Hotdesk',
  icon: 'fire',
  api,
  routingScreens,
};

export default config;
