import { getPeriodLabel } from '../helpers';
import PendingRequest from '../models/PendingRequest';
import { useActionPendingAcceptances } from '../urlopia.hooks';
import SwipeableWithHint from '@common/components/SwipeableWithHint';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import {
  DataTable,
  Surface,
  Text,
  Tooltip,
  useTheme,
} from 'react-native-paper';

type PendingRequestsProps = {
  requests: PendingRequest[];
};

const PendingRequests: React.FC<PendingRequestsProps> = ({ requests = [] }) => {
  const theme = useTheme();
  const { errorContainer, secondaryContainer, surface } = theme.colors;

  const { mutate: actionPendingAcceptances } = useActionPendingAcceptances();

  return (
    <Surface>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={styles.columnName}>
            Wnioskujący
          </DataTable.Title>
          <DataTable.Title numeric style={styles.columnDates}>
            Termin
          </DataTable.Title>
          <DataTable.Title numeric style={styles.columnDays}>
            Liczba dni
          </DataTable.Title>
        </DataTable.Header>

        {requests.map((item, index) => {
          const { requesterName, workingDays, startDate, endDate, id } = item;

          return (
            <SwipeableWithHint
              key={index}
              renderRightActions={() => (
                <>
                  <TouchableOpacity
                    style={[
                      styles.action,
                      { backgroundColor: secondaryContainer },
                    ]}
                    onPress={() => {
                      actionPendingAcceptances({
                        id,
                        status: 'ACCEPTED',
                      });
                    }}
                  >
                    <Text>Akceptuj</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.action, { backgroundColor: errorContainer }]}
                    onPress={() => {
                      actionPendingAcceptances({
                        id,
                        status: 'REJECTED',
                      });
                    }}
                  >
                    <Text>Odrzuć</Text>
                  </TouchableOpacity>
                </>
              )}
            >
              <DataTable.Row style={{ backgroundColor: surface }}>
                <DataTable.Cell style={styles.columnName}>
                  <Tooltip title={requesterName}>
                    <Text>{requesterName}</Text>
                  </Tooltip>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.columnDates}>
                  {getPeriodLabel(startDate, endDate)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.columnDays}>
                  {workingDays}
                </DataTable.Cell>
              </DataTable.Row>
            </SwipeableWithHint>
          );
        })}
      </DataTable>
    </Surface>
  );
};

const styles = StyleSheet.create({
  action: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    minWidth: 70,
  },
  columnName: {
    flex: 0.6,
  },
  columnDates: {
    flex: 0.3,
    flexBasis: 45,
  },
  columnDays: {
    flex: 0.1,
    flexBasis: 35,
  },
});

export default PendingRequests;
