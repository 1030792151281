import { useGetDuePayment } from './jajemto.hooks';
import HistoryScreen from './screens/HistoryScreen';
import MenuScreen from './screens/MenuScreen';
import SettlementScreen from './screens/SettlementScreen';
import Header from '@common/components/Header';
import { Navigation } from '@common/components/Navigation';
import OrderScreen from '@modules/jajemto/screens/OrderScreen';
import ProviderScreen from '@modules/jajemto/screens/ProvidersScreen';
import React, { useEffect } from 'react';
import { Snackbar } from 'react-native-paper';

function JajemtoModule() {
  const { duePayment } = useGetDuePayment();
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  useEffect(() => {
    if (duePayment?.duePayment) {
      setShowSnackbar(true);
    }
  }, [duePayment?.duePayment]);

  return (
    <>
      <Navigation
        header={(props) => <Header {...props} />}
        routes={[
          {
            name: 'OrderScreen',
            title: 'Zamówienie',
            icon: 'basket-outline',
            renderComponent: () => <OrderScreen />,
          },
          {
            name: 'HistoryScreen',
            title: 'Historia',
            icon: 'history',
            renderComponent: () => <HistoryScreen />,
          },
          {
            name: 'SettlementScreen',
            title: 'Rozliczenia',
            icon: 'cash',
            renderComponent: () => <SettlementScreen />,
          },
        ]}
      />
      <Snackbar
        visible={showSnackbar}
        onDismiss={() => {}}
        action={{
          label: 'Zamknij',
          onPress: () => {
            setShowSnackbar(false);
          },
        }}
      >
        Zalegasz z opłatami za obiady. Prosimy o uregulowanie należności.
      </Snackbar>
    </>
  );
}

export default JajemtoModule;
