import { USER_SESSION_LOADING_STATE, userSessionState } from '../state/atoms';
import { getHeaderTitle, HeaderOptions } from '@react-navigation/elements';
import { NavigationProp, ParamListBase, Route } from '@react-navigation/native';
import { Appbar } from 'react-native-paper';
import { useRecoilValue } from 'recoil';

interface HeaderProps {
  customActions?: JSX.Element | JSX.Element[] | undefined;
  accessibilityLabel?: string;
  navigation: NavigationProp<ParamListBase>;
  route: Route<string>;
  options: { title?: string; headerTitle?: HeaderOptions['headerTitle'] };
}

function Header({
  navigation,
  route,
  options,
  customActions,
  accessibilityLabel,
}: HeaderProps) {
  const userSession = useRecoilValue(userSessionState);

  const { canGoBack, goBack } = navigation;

  const title =
    route.name === 'Home' &&
    userSession !== USER_SESSION_LOADING_STATE &&
    userSession
      ? `${userSession.givenName} ${userSession.familyName}`
      : getHeaderTitle(options, route.name);

  return (
    <Appbar.Header
      elevated
      mode='small'
      accessibilityLabel={accessibilityLabel}
    >
      {canGoBack() && <Appbar.BackAction onPress={goBack} />}
      {!canGoBack() && route.name !== 'Home' && (
        <Appbar.Action
          accessibilityLabel='HomeIcon'
          icon='home'
          onPress={() =>
            navigation.reset({
              index: 0,
              routes: [{ name: 'Home' }],
            })
          }
        />
      )}
      <Appbar.Content title={title} />
      {customActions && customActions}
      {route.name !== 'SettingsScreen' && !customActions && (
        <Appbar.Action
          accessibilityLabel='SettingsIcon'
          icon='cog-outline'
          onPress={() => navigation.navigate('SettingsScreen')}
        />
      )}
    </Appbar.Header>
  );
}

export default Header;
