import BookDeskModal from '@modules/hotdesk/components/BookDeskModal';
import { IGetDeskDTO, IGetRoomDTO } from '@modules/hotdesk/hotdesk.types';
import moment, { Moment } from 'moment/moment';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-paper';

type ReservationModalProps = {
  desk: IGetDeskDTO;
  onDismiss: () => void;
  bookDesk: () => void;
  isBookingDesk: boolean;
  activeRoom: IGetRoomDTO | null;
  selectedDay: Moment;
};
function ReservationModal({
  desk,
  onDismiss,
  bookDesk,
  isBookingDesk,
  activeRoom,
  selectedDay,
}: ReservationModalProps) {
  const isPast = selectedDay.endOf('day').isBefore(moment());
  const buttonExit = <Button onPress={onDismiss}>Wyjdź</Button>;
  const buttonBook = (
    <Button onPress={bookDesk} mode='contained' loading={isBookingDesk}>
      Zarezerwuj
    </Button>
  );
  const Actions = () => (
    <>
      {buttonExit}
      {buttonBook}
    </>
  );
  const ActionsForPast = () => <>{buttonExit}</>;

  const Content = () => (
    <Text>
      Czy zarezerwować biurko
      <Text style={styles.bold}> {desk?.name} </Text>w sali
      <Text style={styles.bold}> {activeRoom?.name}</Text>, na dzień
      <Text style={styles.bold}> {selectedDay.format('D MMMM (dddd)')}</Text>?
    </Text>
  );
  const ContentForPast = () => (
    <Text>Nie możesz rezerwować biurek dla dat w przeszłości.</Text>
  );

  return (
    <BookDeskModal
      visible={!!desk}
      onDismiss={() => onDismiss()}
      title={isPast ? 'Ostrzeżenie' : 'Potwierdź rezerwację'}
      actions={isPast ? <ActionsForPast /> : <Actions />}
    >
      {isPast ? <ContentForPast /> : <Content />}
    </BookDeskModal>
  );
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
});

export default ReservationModal;
