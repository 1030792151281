import { useUpdateDesk } from '../hotdeskAdmin.hooks';
import Loader from '@common/components/Loader';
import { IGetDeskDTO, IGetRoomDTO } from '@modules/hotdesk/hotdesk.types';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Switch, View } from 'react-native';
import { Button, List, Text, TextInput } from 'react-native-paper';
import { useQueryClient } from 'react-query';

function DeskDetails({
  desk,
  room,
  resetDesk,
}: {
  desk: IGetDeskDTO;
  room: IGetRoomDTO;
  resetDesk: () => void;
}) {
  const [localDeskCopy, setLocalDeskCopy] = useState<IGetDeskDTO | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    setLocalDeskCopy({ ...desk });
  }, [desk]);

  const handleSetActive = (newValue: boolean) => {
    setLocalDeskCopy({ ...localDeskCopy!, active: newValue });
  };

  const { mutate: updateDesk } = useUpdateDesk(resetDesk, setLocalDeskCopy);

  const setAssignedTo = (assignedTo: string) => {
    setLocalDeskCopy({ ...localDeskCopy!, assignedTo });
  };

  const saveChanges = () => {
    updateDesk({
      id: localDeskCopy!.id,
      payload: {
        assignedTo: localDeskCopy!.active ? '' : localDeskCopy!.assignedTo,
        active: localDeskCopy!.active,
      },
    });
  };

  if (!localDeskCopy) {
    return <Loader />;
  }

  return (
    <View style={styles.margin}>
      <Text variant='titleLarge'>
        Pokój: "{room.name}" - Biurko: "{localDeskCopy.name}""
      </Text>
      <List.Section>
        <List.Item
          title={'Jest HotDeskiem?'}
          right={() => (
            <Switch
              {...(Platform.OS === 'web' ? { pointerEvents: 'box-none' } : {})}
              value={localDeskCopy.active}
              onValueChange={(value) => handleSetActive(value)}
            />
          )}
        />
      </List.Section>
      <TextInput
        label='Stałe biurko zajmowane przez:'
        value={localDeskCopy.active ? '' : localDeskCopy.assignedTo}
        disabled={localDeskCopy.active}
        onChangeText={(text) => setAssignedTo(text)}
      />
      <Button
        style={styles.margin}
        icon='content-save'
        accessibilityLabel='SaveButton'
        onPress={() => saveChanges()}
        mode='contained-tonal'
      >
        Zapisz
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  margin: {
    marginTop: 20,
  },
});

export default DeskDetails;
