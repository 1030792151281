import { pagePadding } from '../../../styles/variables';
import { getPeriodLabel } from '../helpers';
import Statuses from '../models/Statuses';
import { useCancelRequest } from '../urlopia.hooks';
import SwipeableWithHint from '@common/components/SwipeableWithHint';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import {
  DataTable,
  IconButton,
  Surface,
  useTheme,
  Text,
} from 'react-native-paper';

const History = ({ history = [] }) => {
  const theme = useTheme();
  const { secondaryContainer, surface } = theme.colors;

  const { cancelRequest } = useCancelRequest();

  const statusIcon = {
    [Statuses.ACCEPTED]: 'check-circle-outline',
    [Statuses.REJECTED]: 'cancel',
    [Statuses.PENDING]: 'clock-outline',
    [Statuses.CANCELED]: 'close-circle-outline',
    [Statuses.EXPIRED]: 'alert-circle-outline',
  };

  if (history.length === 0) {
    return (
      <Surface>
        <Text style={styles.empty}>Nie złożyłeś jeszcze żadnego wniosku</Text>
      </Surface>
    );
  }

  return (
    <Surface>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Termin</DataTable.Title>
          <DataTable.Title style={styles.centered}>Liczba dni</DataTable.Title>
          <DataTable.Title numeric>Status</DataTable.Title>
        </DataTable.Header>

        {history.map((item, index) => {
          const { startDate, endDate, workingDays, status, id } = item;

          return (
            <SwipeableWithHint
              key={index}
              renderRightActions={
                status === Statuses.PENDING
                  ? () => (
                      <TouchableOpacity
                        style={[
                          styles.action,
                          { backgroundColor: secondaryContainer },
                        ]}
                        onPress={() => {
                          cancelRequest({
                            id,
                          });
                        }}
                      >
                        <Text>Anuluj</Text>
                      </TouchableOpacity>
                    )
                  : null
              }
            >
              <DataTable.Row style={{ backgroundColor: surface }}>
                <DataTable.Cell>
                  {getPeriodLabel(startDate, endDate)}
                </DataTable.Cell>
                <DataTable.Cell style={styles.centered}>
                  {workingDays}
                </DataTable.Cell>
                <DataTable.Cell numeric>
                  <IconButton
                    style={styles.icon}
                    icon={statusIcon[status]}
                    size={20}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            </SwipeableWithHint>
          );
        })}
      </DataTable>
    </Surface>
  );
};

const styles = StyleSheet.create({
  centered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  action: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: pagePadding,
  },
  icon: {
    marginTop: 10,
  },
  empty: {
    textAlign: 'center',
    marginVertical: pagePadding,
  },
});

export default History;
