import Holiday from '../models/Holiday';
import moment, { Moment } from 'moment';
import { DatePickerModal } from 'react-native-paper-dates';

type PickerProps = {
  visible: boolean;
  range:
    | {
        startDate: Moment;
        endDate: Moment;
      }
    | undefined;
  holidays: Holiday[];
  onDismiss: () => void;
  onConfirm: () => void;
};

const Picker: React.FC<PickerProps> = ({
  visible,
  range,
  onDismiss,
  onConfirm,
  holidays,
}) => {
  const min = moment().subtract(1, 'month');
  const max = moment().add(2, 'year');

  const mapedHolidays = holidays.map(({ date }) => moment(date).toDate());

  const weekends = () => {
    let date = min;
    let dates = [];

    while (min.isSameOrBefore(max)) {
      if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
        dates.push(date.toDate());
      }
      date.add(1, 'day');
    }

    return dates;
  };

  return (
    <DatePickerModal
      locale='pl'
      mode='range'
      visible={visible}
      onDismiss={onDismiss}
      startDate={range?.startDate.toDate()}
      endDate={range?.endDate.toDate()}
      onConfirm={onConfirm}
      validRange={{
        startDate: min.toDate(),
        endDate: max.toDate(),
        disabledDates: [...weekends(), ...mapedHolidays],
      }}
      saveLabel='Wybierz'
      label='Wybierz termin urlopu'
      startLabel='od'
      endLabel='do'
      startYear={min.year()}
      endYear={max.year()}
      allowEditing={false}
    />
  );
};

export default Picker;
