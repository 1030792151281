import DriversScreen from './screens/DriversScreen';
import ParkingScreen from './screens/ParkingScreen';
import Header from '@common/components/Header';
import { Navigation } from '@common/components/Navigation';
import React from 'react';

function ParkingModule() {
  return (
    <Navigation
      header={(props) => <Header {...props} />}
      routes={[
        {
          name: 'ParkingScreen',
          title: 'Parking',
          icon: 'parking',
          renderComponent: () => <ParkingScreen />,
        },
        {
          name: 'DriversScreen',
          title: 'Kierowcy',
          icon: 'car',
          renderComponent: () => <DriversScreen />,
        },
      ]}
    />
  );
}

export default ParkingModule;
