import Routes from './src/app/Routes';
import { dark, light } from './src/styles/theme';
import ApiClientProvider from '@common/components/ApiClientProvider';
import UserSessionGuard from '@common/components/UserSessionGuard';
import { isProductionBuild } from '@common/services/appContext';
import { themeModeState } from '@common/state/atoms';
import { linking } from '@config';
import LogRocket from '@logrocket/react-native';
import { NavigationContainer } from '@react-navigation/native';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { StatusBarStyle } from 'expo-status-bar/build/StatusBar.types';
import { useEffect } from 'react';
import { View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import {
  MD3DarkTheme,
  MD3LightTheme,
  Provider as PaperProvider,
} from 'react-native-paper';
import {
  SafeAreaInsetsContext,
  SafeAreaProvider,
} from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useRecoilValue } from 'recoil';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

SplashScreen.preventAutoHideAsync();

const handleHideSplashScreen = () => {
  SplashScreen.hideAsync();
};

export default function App() {
  const themeMode = useRecoilValue(themeModeState);

  useEffect(() => {
    isProductionBuild() &&
      LogRocket.init('ckhojs/fingo', {
        console: {
          isEnabled: {
            log: false,
            debug: false,
          },
        },
      });
  }, []);

  const theme = {
    light: {
      ...MD3LightTheme,
      colors: light,
    },
    dark: {
      ...MD3DarkTheme,
      colors: dark,
    },
  }[themeMode];

  const getThemeMode = (): StatusBarStyle =>
    themeMode === 'dark' ? 'light' : 'dark';

  return (
    <View style={{ backgroundColor: theme?.colors.background, flex: 1 }}>
      <StatusBar style={getThemeMode()} />
      <QueryClientProvider client={queryClient}>
        <PaperProvider theme={theme}>
          <SafeAreaProvider>
            <NavigationContainer linking={linking}>
              <ApiClientProvider>
                <UserSessionGuard hideSplashScreen={handleHideSplashScreen}>
                  <Routes />
                </UserSessionGuard>
              </ApiClientProvider>
              <StatusBar style={getThemeMode()} />
            </NavigationContainer>
            <SafeAreaInsetsContext.Consumer>
              {(insets) => (
                <FlashMessage
                  accessibilityLabel='FlashMessage'
                  icon='auto'
                  position='top'
                  statusBarHeight={insets!.top}
                />
              )}
            </SafeAreaInsetsContext.Consumer>
          </SafeAreaProvider>
        </PaperProvider>
      </QueryClientProvider>
    </View>
  );
}
