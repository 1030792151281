import KontaktyScreen from './screens/KontaktyScreen';
import Header from '@common/components/Header';
import {
  createStackNavigator,
  CardStyleInterpolators,
} from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

const Stack = createStackNavigator();

function KontaktyModule() {
  const cardStyleInterpolator =
    Platform.OS === 'android'
      ? CardStyleInterpolators.forFadeFromBottomAndroid
      : CardStyleInterpolators.forHorizontalIOS;

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='KontaktyScreen'
        options={{
          header: (props) => <Header {...props} />,
          title: 'Kontakty',
          cardStyleInterpolator,
        }}
        component={KontaktyScreen}
      />
    </Stack.Navigator>
  );
}

export default KontaktyModule;
