import { ApiClientContext, ApiClientType } from '../services/apiClient';
import { useContext } from 'react';
import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from 'react-query';

interface UseQueryParams<
  TQueryFnData,
  TError,
  TData,
  TQueryKey extends QueryKey
> extends Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryFn'
  > {
  queryFn: (apiClient: ApiClientType) => QueryFunction<TQueryFnData, QueryKey>;
}

export default <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queryFn: fn,
  ...queryParams
}: UseQueryParams<TQueryFnData, TError, TData, TQueryKey>) => {
  const api = useContext(ApiClientContext);

  return useQuery({
    queryFn: fn(api),
    retry: 1,
    ...queryParams,
  });
};
