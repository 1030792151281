export const light = {
  primary: 'rgb(47, 107, 38)',
  onPrimary: 'rgb(255, 255, 255)',
  primaryContainer: 'rgb(177, 244, 157)',
  onPrimaryContainer: 'rgb(0, 34, 0)',
  secondary: 'rgb(84, 99, 77)',
  onSecondary: 'rgb(255, 255, 255)',
  secondaryContainer: 'rgb(215, 232, 205)',
  onSecondaryContainer: 'rgb(18, 31, 14)',
  tertiary: 'rgb(56, 101, 104)',
  onTertiary: 'rgb(255, 255, 255)',
  tertiaryContainer: 'rgb(188, 235, 238)',
  onTertiaryContainer: 'rgb(0, 32, 34)',
  error: 'rgb(186, 26, 26)',
  onError: 'rgb(255, 255, 255)',
  errorContainer: 'rgb(255, 218, 214)',
  onErrorContainer: 'rgb(65, 0, 2)',
  background: 'rgb(252, 253, 246)',
  onBackground: 'rgb(26, 28, 24)',
  surface: 'rgb(252, 253, 246)',
  onSurface: 'rgb(26, 28, 24)',
  surfaceVariant: 'rgb(223, 228, 215)',
  onSurfaceVariant: 'rgb(67, 72, 63)',
  outline: 'rgb(115, 121, 110)',
  outlineVariant: 'rgb(195, 200, 188)',
  shadow: 'rgb(0, 0, 0)',
  scrim: 'rgb(0, 0, 0)',
  inverseSurface: 'rgb(47, 49, 45)',
  inverseOnSurface: 'rgb(241, 241, 235)',
  inversePrimary: 'rgb(150, 215, 132)',
  elevation: {
    level0: 'transparent',
    level1: 'rgb(242, 246, 236)',
    level2: 'rgb(236, 241, 229)',
    level3: 'rgb(229, 237, 223)',
    level4: 'rgb(227, 236, 221)',
    level5: 'rgb(223, 233, 217)',
  },
  surfaceDisabled: 'rgba(26, 28, 24, 0.12)',
  onSurfaceDisabled: 'rgba(26, 28, 24, 0.38)',
  backdrop: 'rgba(44, 50, 41, 0.4)',
};

export const dark = {
  primary: 'rgb(150, 215, 132)',
  onPrimary: 'rgb(0, 58, 0)',
  primaryContainer: 'rgb(21, 82, 15)',
  onPrimaryContainer: 'rgb(177, 244, 157)',
  secondary: 'rgb(187, 203, 178)',
  onSecondary: 'rgb(38, 52, 34)',
  secondaryContainer: 'rgb(60, 75, 55)',
  onSecondaryContainer: 'rgb(215, 232, 205)',
  tertiary: 'rgb(160, 207, 210)',
  onTertiary: 'rgb(0, 55, 57)',
  tertiaryContainer: 'rgb(30, 77, 80)',
  onTertiaryContainer: 'rgb(188, 235, 238)',
  error: 'rgb(255, 180, 171)',
  onError: 'rgb(105, 0, 5)',
  errorContainer: 'rgb(147, 0, 10)',
  onErrorContainer: 'rgb(255, 180, 171)',
  background: 'rgb(26, 28, 24)',
  onBackground: 'rgb(226, 227, 220)',
  surface: 'rgb(26, 28, 24)',
  onSurface: 'rgb(226, 227, 220)',
  surfaceVariant: 'rgb(67, 72, 63)',
  onSurfaceVariant: 'rgb(195, 200, 188)',
  outline: 'rgb(141, 147, 135)',
  outlineVariant: 'rgb(67, 72, 63)',
  shadow: 'rgb(0, 0, 0)',
  scrim: 'rgb(0, 0, 0)',
  inverseSurface: 'rgb(226, 227, 220)',
  inverseOnSurface: 'rgb(47, 49, 45)',
  inversePrimary: 'rgb(47, 107, 38)',
  elevation: {
    level0: 'transparent',
    level1: 'rgb(32, 37, 29)',
    level2: 'rgb(36, 43, 33)',
    level3: 'rgb(40, 49, 36)',
    level4: 'rgb(41, 50, 37)',
    level5: 'rgb(43, 54, 39)',
  },
  surfaceDisabled: 'rgba(226, 227, 220, 0.12)',
  onSurfaceDisabled: 'rgba(226, 227, 220, 0.38)',
  backdrop: 'rgba(44, 50, 41, 0.4)',
};
