import sygnet from '../../../assets/logo.png';
import { pagePadding } from '../../styles/variables';
import Screen from '@common/components/Screen';
import useLogout from '@common/hooks/useLogout';
import { ApiClientContext } from '@common/services/apiClient';
import { isProductionBuild } from '@common/services/appContext';
import auth, { getUserSession } from '@common/services/auth';
import { userSessionState } from '@common/state/atoms';
import { oidc } from '@config';
import LogRocket from '@logrocket/react-native';
import {
  exchangeCodeAsync,
  makeRedirectUri,
  Prompt,
  useAuthRequest,
  useAutoDiscovery,
} from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { useContext, useEffect, useState } from 'react';
import { Image, SafeAreaView, View, StyleSheet, Platform } from 'react-native';
import { Button, Text, Surface } from 'react-native-paper';
import { useSetRecoilState } from 'recoil';

WebBrowser.maybeCompleteAuthSession();

const Auth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const setUserSession = useSetRecoilState(userSessionState);
  const logout = useLogout();
  const api = useContext(ApiClientContext);

  const discovery = useAutoDiscovery(oidc.autoDiscovery);
  const redirectUri = makeRedirectUri({
    scheme: oidc.fingoScheme,
    path: 'auth',
  });

  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: oidc.clientId,
      scopes: oidc.scopes,
      redirectUri,
      prompt: Prompt.SelectAccount,
    },
    discovery
  );

  useEffect(() => {
    if (response?.params?.code) {
      setIsLoading(true);
      exchangeCodeAsync(
        {
          code: response.params.code,
          clientId: oidc.clientId,
          redirectUri,
          extraParams: {
            code_verifier: request?.codeVerifier || '',
          },
        },
        {
          tokenEndpoint: oidc.tokenEndpoint,
        }
      ).then((tokensResponse) => {
        const session = getUserSession(tokensResponse);
        setUserSession(session);
        api.setAuth(
          auth({
            userSession: session,
            setUserSession,
            logout,
          })
        );
        if (isProductionBuild()) LogRocket.identify(session.email);
        setIsLoading(false);
      });
    }
  }, [response]);

  return (
    <Screen loading={isLoading}>
      <SafeAreaView accessibilityLabel='WelcomePage' style={styles.container}>
        <Surface style={styles.surface} elevation={2}>
          <Image source={sygnet} style={styles.logo} />
          <View>
            <Text style={styles.title}>Witaj w aplikacji FINGO</Text>
            <Text style={styles.description}>
              Aby kontynuować, zaloguj się swoim kontem Microsoft Active
              Directory.
            </Text>
          </View>
          <Button
            accessibilityLabel='WelcomeLogin'
            disabled={!request}
            mode='text'
            style={styles.button}
            onPress={() => {
              promptAsync({ showInRecents: true });
            }}
          >
            Zaloguj się
          </Button>
        </Surface>
      </SafeAreaView>
    </Screen>
  );
};

const styles = StyleSheet.create({
  surface: {
    borderRadius: 20,
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: pagePadding,
    ...Platform.select({
      web: {
        width: '50%',
        alignSelf: 'center',
      },
      default: {},
    }),
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: pagePadding,
  },
  description: {
    textAlign: 'center',
    maxWidth: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: pagePadding,
  },
  logo: {
    alignSelf: 'center',
    justifyContent: 'center',
    height: 90,
    width: 90,
    marginBottom: 15,
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
  },
  button: {
    marginTop: pagePadding,
  },
});

export default Auth;
