import { pagePadding } from '../../styles/variables';
import ErrorMessage from './ErrorMessage';
import Loader from './Loader';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type ScreenProps = {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
  customStyle?: object;
  isError?: boolean;
};

const Screen: React.FC<ScreenProps> = ({
  children,
  loading,
  customStyle = {},
  isError = false,
}) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  const dynamic = {
    backgroundColor: theme.colors.background,
    paddingBottom: insets.bottom + pagePadding,
  };

  if (isError) return <ErrorMessage style={dynamic} />;
  if (loading) return <Loader />;

  return <View style={[styles.screen, dynamic, customStyle]}>{children}</View>;
};

const styles = StyleSheet.create({
  screen: {
    padding: pagePadding,
    flex: 1,
  },
});

export default Screen;
