import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useTheme } from 'react-native-paper';

const NoMenu = () => {
  const theme = useTheme();
  const { error } = theme.colors;

  return (
    <View>
      <Text style={[styles.label, { color: error }]}>
        Menu żadnego dostawcy nie jest teraz dostępne.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    textAlign: 'center',
  },
});
export default NoMenu;
