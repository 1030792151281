import History from '../components/History';
import { useDeleteOrder, useGetHistory } from '../jajemto.hooks';
import Screen from '@common/components/Screen';
import React, { useMemo } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

const HistoryScreen = () => {
  const { history, isLoading, isRefetching, isError } = useGetHistory();
  const { deleteOrder, isDeletingOrder } = useDeleteOrder();

  const reversedOrders = useMemo(() => {
    if (!history?.orders) return [];
    return history.orders.reverse();
  }, [history]);

  return (
    <Screen
      loading={isDeletingOrder || isLoading || isRefetching}
      isError={isError}
    >
      <GestureHandlerRootView>
        <History orders={reversedOrders} onDelete={deleteOrder} />
      </GestureHandlerRootView>
    </Screen>
  );
};

export default HistoryScreen;
