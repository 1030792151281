import { pagePadding } from '../../../styles/variables';
import Additions from '@modules/jajemto/models/Additions';
import Dish from '@modules/jajemto/models/Dish';
import { getAmountString } from '@modules/jajemto/utils/currency';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { List, RadioButton, Button, useTheme } from 'react-native-paper';

interface DishCompositionWidgetProps {
  dish?: Dish;
  onSelection: (dish: Dish) => void;
}

export const DishCompositionWidget = ({
  dish,
  onSelection,
}: DishCompositionWidgetProps) => {
  const [additionsSelection, setAdditionsSelection] = useState<Additions[]>([]);
  const theme = useTheme();
  const { primary } = theme.colors;

  const onValueChange = (groupId: number, optionId: string) => {
    setAdditionsSelection((prevAdditionsSelection) => {
      const newAdditionSelection = [...prevAdditionsSelection];
      const additions = newAdditionSelection.find(
        (additions) => additions.id === groupId
      );
      if (additions) {
        additions.options.forEach((option) => {
          option.checked = option.id === parseInt(optionId);
        });
      }
      return newAdditionSelection;
    });
  };

  useEffect(() => {
    if (dish) {
      setAdditionsSelection([...dish.addings]);
    }
  }, [dish]);

  const handleSelection = () => {
    if (dish) {
      onSelection({ ...dish, addings: additionsSelection });
    }
  };

  const getSelectedOptionId = (groupId: number): string =>
    additionsSelection
      ?.find((adds) => adds.id === groupId)
      ?.options.find((opt) => opt.checked)
      ?.id.toString() || '';

  return (
    <View>
      {dish?.addings
        .filter((addings) => addings.type === 'radio')
        .map((addings) => (
          <View key={addings.id}>
            <Text style={{ color: primary }}>{addings.name}</Text>
            <List.Section>
              <RadioButton.Group
                value={getSelectedOptionId(addings.id)}
                onValueChange={(value: string) =>
                  onValueChange(addings.id, value)
                }
              >
                <>
                  {addings.options.map((option) => (
                    <RadioButton.Item
                      key={option.id}
                      label={`${option.name} +${getAmountString(option.price)}`}
                      value={option.id.toString()}
                    />
                  ))}
                </>
              </RadioButton.Group>
            </List.Section>
          </View>
        ))}
      <Button onPress={handleSelection} style={styles.button} mode='contained'>
        Zapisz
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: pagePadding,
  },
});
