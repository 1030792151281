import { getAmountString } from '../utils/currency';
import currency from 'currency.js';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { Text, useTheme } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

type LimitProps = {
  remainingLimit: currency;
};

type AchievedProps = {
  color: string;
};

type NonAchievedProps = {
  color: string;
  remainingLimit: currency;
};

const Achieved = ({ color }: AchievedProps) => (
  <>
    <Icon name='check-decagram' size={16} color={color} />
    <Text
      style={{
        marginLeft: 4,
        color,
        fontSize: 12,
      }}
    >
      limit osiągnięto
    </Text>
  </>
);

const NotAchieved = ({ color, remainingLimit }: NonAchievedProps) => (
  <Animatable.View
    style={styles.limitWrapper}
    animation='flash'
    duration={3500}
    iterationCount='infinite'
  >
    <Icon name='information' size={16} color={color} />
    <Text
      style={{
        marginLeft: 4,
        color,
        fontSize: 12,
      }}
    >
      limit:{' '}
      <Text
        style={{
          color,
          fontWeight: 'bold',
        }}
      >
        {getAmountString(remainingLimit)}
      </Text>
    </Text>
  </Animatable.View>
);

const Limit: React.FC<LimitProps> = ({ remainingLimit }) => {
  const theme = useTheme();
  const { primary } = theme.colors;
  const color = theme.dark ? '#eac248' : '#fd9b3f';

  return (
    <View style={styles.limitWrapper}>
      {Number(remainingLimit) !== 0 ? (
        <NotAchieved color={color} remainingLimit={remainingLimit} />
      ) : (
        <Achieved color={primary} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  limitWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  limitLabel: {
    marginLeft: 4,
    textAlign: 'center',
  },
});

export default Limit;
