import { pagePadding } from '../../../styles/variables';
import DatePicker from '../components/DatePicker';
import RoomPreview from '../components/RoomPreview/RoomPreview';
import {
  useBookingDesk,
  useCancelingBooking,
  useGetRooms,
  useRefreshDesks,
} from '../hotdesk.hooks';
import { IGetDeskDTO, IGetRoomDTO } from '../hotdesk.types';
import Loader from '@common/components/Loader';
import Screen from '@common/components/Screen';
import { UserSession } from '@common/services/auth';
import {
  USER_SESSION_LOADING_STATE,
  userSessionState,
} from '@common/state/atoms';
import { HotDeskParamList } from '@modules/hotdesk';
import InformationModal from '@modules/hotdesk/components/InformationModal';
import ManageBookingModal from '@modules/hotdesk/components/ManageBookingModal';
import ReservationModal from '@modules/hotdesk/components/ReservationModal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RefreshControl, ScrollView, View } from 'react-native';
import { SegmentedButtons, Text } from 'react-native-paper';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import { useRecoilValue } from 'recoil';

type Props = NativeStackScreenProps<HotDeskParamList, 'HotdeskScreen'>;

enum Status {
  Assigned = 'Assigned',
  Booked = 'Booked',
  Free = 'Free',
}

enum StatusColours {
  Assigned = 'gray',
  Booked = 'darkred',
  Free = 'green',
  Default = 'gray',
}

function HotdeskScreen({ route, navigation }: Props) {
  const userSession = useRecoilValue(userSessionState);
  const { rooms, isLoadingRooms, isError } = useGetRooms();
  const { bookDesk, isBookingDesk } = useBookingDesk();
  const { cancelBooking, isCancelingBooking } = useCancelingBooking();
  const [activeRoom, setActiveRoom] = useState<IGetRoomDTO | null>(null);
  const [selectedDesk, setSelectedDesk] = useState<IGetDeskDTO | null>(null);
  const [refreshing, setRefreshing] = React.useState(false);
  const { refreshDesks } = useRefreshDesks();
  const selectedDay = moment(route.params.selectedDay);
  const setSelectedDay = (day: moment.Moment) =>
    navigation.setParams({ selectedDay: day.format('YYYY-MM-DD') });

  useEffect(() => {
    if (rooms && rooms?.length > 0) {
      setActiveRoom(rooms[0]);
    }
  }, [rooms]);

  const isUserSessionLoading = () => userSession === USER_SESSION_LOADING_STATE;

  if (isUserSessionLoading()) {
    return <Loader />;
  }

  const handleSetActiveRoom = (roomId: string) => {
    const room = rooms!.find(
      ({ id }: { id: number }) => id === parseInt(roomId, 10)
    );

    if (room) {
      setActiveRoom(room);
    }
  };

  const handleBookDesk = () => {
    const userDisplayName = `${userSession!.givenName} ${
      userSession!.familyName
    }`;

    bookDesk(
      {
        deskId: selectedDesk?.id.toString(),
        userId: extractUsernameFromEmail(userSession!.email),
        userDisplayName: userDisplayName,
        date: selectedDay.format('Y-MM-D'),
      },
      {
        onSuccess: () => {
          setSelectedDesk(null);
        },
      }
    );
  };

  const handleCancelBooking = () => {
    cancelBooking(selectedDesk?.reservations[0].id, {
      onSuccess: () => {
        setSelectedDesk(null);
      },
    });
  };

  const getDeskStatus = (desk: IGetDeskDTO): Status => {
    if (isBooked(desk)) {
      return Status.Booked;
    }
    if (isAssigned(desk)) {
      return Status.Assigned;
    }
    return Status.Free;
  };

  const isBooked = (desk: IGetDeskDTO) => desk && desk.reservations!.length > 0;

  const isAssigned = (desk: IGetDeskDTO) => desk && !desk.active;

  const isOwnReservation = (desk: IGetDeskDTO, userSession: UserSession) =>
    selectedDesk?.reservations[0].userId ===
    extractUsernameFromEmail(userSession.email);

  const extractUsernameFromEmail = (email: string): string =>
    email.split('@')[0].replace(/(-old)$/, '');

  const canManageDesk = (
    desk: IGetDeskDTO,
    userSession: UserSession
  ): boolean => isBooked(desk) && isOwnReservation(desk, userSession);

  const triggerRefresh = async () => {
    setRefreshing(true);
    await refreshDesks();
    setRefreshing(false);
  };

  const deskPainter = (desk: IGetDeskDTO): string =>
    desk ? StatusColours[getDeskStatus(desk)] : StatusColours.Default;

  const Modal = ({
    desk,
    userSession,
  }: {
    desk: IGetDeskDTO;
    userSession: UserSession;
  }) => {
    const status = getDeskStatus(desk);
    if (status === Status.Free) {
      return (
        <ReservationModal
          isBookingDesk={isBookingDesk}
          desk={desk}
          onDismiss={() => setSelectedDesk(null)}
          bookDesk={handleBookDesk}
          activeRoom={activeRoom}
          selectedDay={selectedDay}
        />
      );
    }

    if (status === Status.Booked) {
      return (
        <ManageBookingModal
          desk={desk}
          isOwnBooking={canManageDesk(desk, userSession)}
          onDismiss={() => setSelectedDesk(null)}
          cancelBooking={handleCancelBooking}
          isCancelingBooking={isCancelingBooking}
          activeRoom={activeRoom}
          selectedDay={selectedDay}
        />
      );
    }

    if (status === Status.Assigned) {
      return (
        <InformationModal
          desk={desk}
          onDismiss={() => setSelectedDesk(null)}
          activeRoom={activeRoom}
        />
      );
    }

    return null;
  };

  return (
    <Screen loading={isLoadingRooms} isError={isError}>
      <View style={{ marginBottom: 10, alignItems: 'center' }}>
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={triggerRefresh}
            />
          }
          style={{ height: 80 }}
        >
          <DatePicker
            selectedDay={selectedDay}
            next={() => setSelectedDay(selectedDay.clone().add(1, 'days'))}
            prev={() => setSelectedDay(selectedDay.clone().subtract(1, 'days'))}
            updateSelectedDate={(date: Date) => setSelectedDay(moment(date))}
          />
          <ScrollView horizontal>
            <SegmentedButtons
              value={activeRoom?.id.toString()!}
              density='small'
              style={{ marginTop: pagePadding }}
              onValueChange={(value: string) => handleSetActiveRoom(value)}
              buttons={rooms!.map(
                ({ id, name }: { id: number; name: string }) => ({
                  value: id.toString(),
                  label: name,
                  style: {
                    flexBasis: 'auto',
                  },
                })
              )}
            />
          </ScrollView>
        </ScrollView>
        <Text style={{ marginTop: 20 }}>
          Kliknij na biurko, aby zarezerwować.
        </Text>
      </View>

      {activeRoom ? (
        <RoomPreview
          room={activeRoom}
          handleSetSelected={setSelectedDesk}
          date={selectedDay}
          deskPainter={deskPainter}
        />
      ) : (
        <></>
      )}
      {selectedDesk && !isUserSessionLoading() ? (
        <Modal desk={selectedDesk} userSession={userSession} />
      ) : (
        <></>
      )}
    </Screen>
  );
}

export default HotdeskScreen;
