import Auth from './screens/Auth';
import Home from './screens/Home';
import SettingsScreen from './screens/SettingsScreen';
import Header from '@common/components/Header';
import { userSessionState } from '@common/state/atoms';
import { modules } from '@config';
import HotdeskModule from '@modules/hotdesk';
import HotdeskAdminModule from '@modules/hotdeskAdmin';
import JajemtoModule from '@modules/jajemto';
import KontaktyModule from '@modules/kontakty';
import ParkingModule from '@modules/parking';
import UrlopiaModule from '@modules/urlopia';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { Platform, StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';

const Stack = createStackNavigator();

function ProtectedRoutes() {
  return (
    <>
      <Stack.Screen name='Home' component={Home} />
      <Stack.Group screenOptions={{ headerShown: false }}>
        <Stack.Screen name={modules.parking.name} component={ParkingModule} />
        <Stack.Screen name={modules.urlopia.name} component={UrlopiaModule} />
        <Stack.Screen name={modules.jajemto.name} component={JajemtoModule} />
        <Stack.Screen name={modules.kontakty.name} component={KontaktyModule} />
        <Stack.Screen name={modules.hotdesk.name} component={HotdeskModule} />
        <Stack.Screen
          name={modules.hotdeskAdmin.name}
          component={HotdeskAdminModule}
        />
      </Stack.Group>
      <Stack.Screen
        name='SettingsScreen'
        options={{
          title: 'Ustawienia',
        }}
        component={SettingsScreen}
      />
    </>
  );
}

function GuestRoutes() {
  return (
    <Stack.Screen
      name='Login'
      options={{
        headerShown: false,
      }}
      component={Auth}
    />
  );
}

export default function () {
  const userSession = useRecoilValue(userSessionState);
  const cardStyleInterpolator =
    Platform.OS === 'android'
      ? CardStyleInterpolators.forFadeFromBottomAndroid
      : CardStyleInterpolators.forHorizontalIOS;
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => <Header {...props} />,
        cardStyleInterpolator,
        animationEnabled: Platform.OS !== 'web',
      }}
    >
      {userSession ? ProtectedRoutes() : GuestRoutes()}
    </Stack.Navigator>
  );
}
