import { pagePadding } from '../../../styles/variables';
import { useGetPhoto, useOpenTheGate } from '../parking.hooks';
import Loader from '@common/components/Loader';
import Screen from '@common/components/Screen';
import React from 'react';
import {
  Image,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { Card, Button } from 'react-native-paper';

function ParkingScreen() {
  const { photo, isPhotoLoading, refetch } = useGetPhoto();

  const { mutate: open, isOpening } = useOpenTheGate();

  const [refreshing, setRefreshing] = React.useState(false);

  const triggerRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  return (
    <Screen>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={triggerRefresh} />
        }
      >
        <Card>
          {isPhotoLoading ? (
            <Loader />
          ) : (
            <Image
              accessibilityLabel='ParkingImage'
              style={styles.picture}
              source={{ uri: `data:image/jpeg;base64,${photo}` }}
            />
          )}
        </Card>
        <View accessibilityLabel='ParkingPage' style={styles.row}>
          <View style={styles.column}>
            <Button
              icon='camera-flip'
              mode='outlined'
              onPress={() => refetch()}
              accessibilityLabel='RefreshParkingViewButton'
            >
              Odśwież podgląd
            </Button>
          </View>
          <View style={styles.gap} />
          <View style={styles.column}>
            <Button
              accessibilityLabel='OpenGateButton'
              mode='contained'
              icon='lock-open'
              onPress={() => open()}
              loading={isOpening}
            >
              {isOpening ? 'Otwieram...' : 'Otwórz bramę'}
            </Button>
          </View>
        </View>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  picture: {
    width: '100%',
    height: 'auto',
    aspectRatio: 1.77,
  },
  row: {
    flexDirection: 'row',
    marginVertical: pagePadding,
  },
  column: {
    flex: 1,
  },
  gap: {
    width: pagePadding,
  },
});

export default ParkingScreen;
