import {
  ApiConfig,
  EndpointConfig,
  EnvPrefixes,
  ModuleConfig,
} from '../../global.types';
import { HTTPMethod } from '@common/enums/Http';

export type ParkingApi = {
  open: EndpointConfig;
  photo: EndpointConfig;
  findDrivers: EndpointConfig;
};

const envPrefixes: EnvPrefixes = {
  development: '/darek/v1',
  production: '/darek/v1',
};

const api: ApiConfig<ParkingApi> = {
  baseUrl: 'https://apigateway.fingo.info',
  envPrefixes,
  endpoints: {
    open: { path: `/open-the-gate`, method: HTTPMethod.GET, timeout: 20000 },
    photo: { path: `/get-photo`, method: HTTPMethod.GET },
    findDrivers: { path: `/api/gate/drivers`, method: HTTPMethod.GET },
  },
};

const routingScreens = {
  Parking: {
    path: 'parking',
    screens: {
      ParkingScreen: '',
      DriversScreen: 'kierowcy',
    },
  },
};

const config: ModuleConfig = {
  web: true,
  name: 'Parking',
  icon: 'parking',
  api,
  routingScreens,
};

export default config;
