import {
  BottomTabHeaderProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import {
  RouteProp,
  ParamListBase,
  CommonActions,
} from '@react-navigation/native';
import { BottomNavigation } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

interface RenderComponentProps {
  route: RouteProp<ParamListBase>;
  navigation: any;
}

interface Route {
  name: string;
  title: string;
  icon: string;
  renderComponent: (props: RenderComponentProps) => JSX.Element;
  badge?: string | number;
}

interface Props {
  routes: Route[];
  header: (props: BottomTabHeaderProps) => React.ReactNode;
}

const Tab = createBottomTabNavigator();

export const Navigation = ({ routes, header }: Props) => {
  return (
    <Tab.Navigator
      screenOptions={{
        header: header,
      }}
      tabBar={({ navigation, state, descriptors, insets }) => (
        <BottomNavigation.Bar
          navigationState={state}
          safeAreaInsets={insets}
          onTabPress={({ route, preventDefault }) => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (event.defaultPrevented) {
              preventDefault();
            } else {
              navigation.dispatch({
                ...CommonActions.navigate(route.name, route.params),
                target: state.key,
              });
            }
          }}
          renderIcon={({ route, focused, color }) => {
            const { options } = descriptors[route.key];
            if (options.tabBarIcon) {
              return options.tabBarIcon({ focused, color, size: 24 });
            }

            return null;
          }}
          getLabelText={({ route }) => {
            const { options } = descriptors[route.key];
            return options.title;
          }}
          getBadge={({ route }) => {
            const { options } = descriptors[route.key];

            return options.tabBarBadge;
          }}
        />
      )}
    >
      {routes.map((route) => (
        <Tab.Screen
          key={route.name}
          name={route.name}
          options={{
            title: route.title,
            tabBarIcon: ({ color, size }) => (
              <Icon name={route.icon} color={color} size={size} />
            ),
            tabBarBadge: route.badge,
          }}
        >
          {route.renderComponent}
        </Tab.Screen>
      ))}
    </Tab.Navigator>
  );
};
