import { pagePadding } from '../../../styles/variables';
import { IHistoryOrderDTO } from '../jajemto.types';
import { Status } from '../models/Order';
import { getAmountString } from '../utils/currency';
import SwipeableWithHint from '@common/components/SwipeableWithHint';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import {
  DataTable,
  IconButton,
  Surface,
  Text,
  useTheme,
} from 'react-native-paper';

type HistoryProps = {
  orders: IHistoryOrderDTO[];
  onDelete: (id: number) => void;
};

const statusIcon = {
  [Status.OPEN]: 'clock-outline',
  [Status.CLOSED]: 'lock-check-outline',
};

const History: React.FC<HistoryProps> = ({ orders, onDelete }) => {
  const perPage = 10;
  const theme = useTheme();
  const { secondaryContainer, surface } = theme.colors;

  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = useState(perPage);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, orders.length);

  useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage]);

  if (orders.length === 0) {
    return (
      <Surface>
        <Text style={styles.empty}>
          Nie złożyłeś jeszcze żadnego zamówienia!
        </Text>
      </Surface>
    );
  }

  return (
    <Surface>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={styles.columnDate}>Data</DataTable.Title>
          <DataTable.Title style={styles.columnName}>Danie</DataTable.Title>
          <DataTable.Title numeric style={styles.columnPrice}>
            Kwota
          </DataTable.Title>
          <DataTable.Title numeric style={styles.columnIcons}>
            Status
          </DataTable.Title>
        </DataTable.Header>

        {orders.slice(from, to).map(({ status, dish, id, menu, cost }) => {
          return (
            <SwipeableWithHint
              key={id}
              renderRightActions={
                status === Status.OPEN
                  ? () => (
                      <TouchableOpacity
                        style={[
                          styles.action,
                          { backgroundColor: secondaryContainer },
                        ]}
                        onPress={() => onDelete(id)}
                      >
                        <Text>Anuluj</Text>
                      </TouchableOpacity>
                    )
                  : null
              }
            >
              <DataTable.Row style={{ backgroundColor: surface }}>
                <DataTable.Cell style={styles.columnDate}>
                  {moment(menu.valid_on).format('DD.MM.YYYY')}
                </DataTable.Cell>
                <DataTable.Cell style={styles.columnName}>
                  {dish.description.trim().length > 0
                    ? dish.description
                    : dish.name}
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.columnPrice}>
                  {getAmountString(cost)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.columnIcons}>
                  <IconButton
                    style={styles.icon}
                    icon={statusIcon[status]}
                    size={20}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            </SwipeableWithHint>
          );
        })}
        <>
          {orders.length > perPage && (
            <DataTable.Pagination
              page={page}
              numberOfPages={Math.ceil(orders.length / numberOfItemsPerPage)}
              onPageChange={(newPage) => setPage(newPage)}
              label={`${from + 1}-${to} na ${orders.length}`}
              numberOfItemsPerPage={numberOfItemsPerPage}
              onItemsPerPageChange={onItemsPerPageChange}
            />
          )}
        </>
      </DataTable>
    </Surface>
  );
};

const styles = StyleSheet.create({
  action: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    minWidth: 70,
  },
  icon: {
    marginTop: 10,
  },
  empty: {
    textAlign: 'center',
    marginVertical: pagePadding,
  },
  columnDate: {
    flex: 0.1,
    flexBasis: 55,
  },
  columnName: {
    flex: 0.7,
  },
  columnPrice: {
    flex: 0.1,
    flexBasis: 30,
  },
  columnIcons: {
    flex: 0.1,
    flexBasis: 20,
  },
});

export default History;
