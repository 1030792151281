import { swipeHintState } from '../state/atoms';
import SwipeHint from './SwipeHint';
import { Swipeable } from 'react-native-gesture-handler';
import { useRecoilState } from 'recoil';

type SwipeableWithHintProps = {
  renderRightActions: any;
  children: JSX.Element | JSX.Element[];
};

const SwipeableWithHint: React.FC<SwipeableWithHintProps> = ({
  renderRightActions,
  children,
}) => {
  const [show, setShow] = useRecoilState(swipeHintState);

  const handleOnSwipe = () => {
    if (show === 'false') return;
    setShow('false');
  };

  return (
    <Swipeable
      renderRightActions={renderRightActions}
      onSwipeableOpen={() => handleOnSwipe()}
    >
      {children}
      {renderRightActions && <SwipeHint />}
    </Swipeable>
  );
};
export default SwipeableWithHint;
