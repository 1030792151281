import Modal from '@modules/hotdesk/components/Modal';
import React from 'react';
import { Platform } from 'react-native';

function BookDeskModal({
  visible,
  onDismiss,
  title,
  children,
  actions,
}: {
  visible: boolean;
  onDismiss: () => void;
  title: string;
  children: JSX.Element;
  actions: JSX.Element;
}) {
  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      title={title}
      actions={actions}
      turnOffDialogAnimation={Platform.OS === 'web'}
    >
      {children}
    </Modal>
  );
}
export default BookDeskModal;
