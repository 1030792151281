import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ActivityIndicator, useTheme } from 'react-native-paper';

const Loader = () => {
  const theme = useTheme();

  const themed = {
    backgroundColor: theme.colors.background,
  };

  return (
    <View style={[styles.container, themed]}>
      <ActivityIndicator size='large' animating={true} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Loader;
