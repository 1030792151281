import {
  ApiConfig,
  EndpointConfig,
  EnvPrefixes,
  ModuleConfig,
} from '../../global.types';
import { HTTPMethod } from '@common/enums/Http';
import moment from 'moment';

export type UrlopiaApi = {
  session: EndpointConfig;
  holidays: EndpointConfig;
  history: EndpointConfig;
  vacationDays: EndpointConfig;
  vacations: EndpointConfig;
  calendar: EndpointConfig;
  pendingDays: EndpointConfig;
  absenceRequest: EndpointConfig;
  getPendingAcceptances: EndpointConfig;
  actionPendingAcceptances: EndpointConfig;
  cancelRequest: EndpointConfig;
  presenceConfirmations: EndpointConfig;
  presenceConfirmation: EndpointConfig;
  userDetails: EndpointConfig;
  workingHours: EndpointConfig;
};

const envPrefixes: EnvPrefixes = {
  development: '/urlopia-dev/v2',
  production: '/urlopia/v2',
};

const api: ApiConfig<UrlopiaApi> = {
  baseUrl: 'https://apigateway.fingo.info',
  envPrefixes,
  endpoints: {
    session: { path: `/session`, method: HTTPMethod.POST },
    holidays: {
      path: `/holidays?filter=date>:${moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD')}`,
      method: HTTPMethod.GET,
    },
    history: {
      path: `/absence-requests/requester/me?filter=endDate>:${moment().format(
        'YYYY-MM-DD'
      )}|status:PENDING&sort=id,DESC&size=8`,
      method: HTTPMethod.GET,
    },
    vacationDays: {
      path: `/users/me/vacation-days`,
      method: HTTPMethod.GET,
    },
    vacations: {
      path: `/calendar/users/{userId}/vacations`,
      method: HTTPMethod.GET,
    },
    calendar: {
      path: `/calendar?startDate={startDate}&endDate={endDate}`,
      method: HTTPMethod.GET,
    },
    pendingDays: {
      path: `/users/me/pending-days`,
      method: HTTPMethod.GET,
    },
    absenceRequest: {
      path: `/absence-requests`,
      method: HTTPMethod.POST,
    },
    getPendingAcceptances: {
      path: `/absence-request-acceptances?filter=status:PENDING&sort=id,DESC&size=999`,
      method: HTTPMethod.GET,
    },
    actionPendingAcceptances: {
      path: `/absence-request-acceptances/{id}`,
      method: HTTPMethod.PATCH,
    },
    cancelRequest: {
      path: `/absence-requests/{id}`,
      method: HTTPMethod.PATCH,
    },
    presenceConfirmations: {
      path: `/presence-confirmations`,
      method: HTTPMethod.GET,
    },
    presenceConfirmation: {
      path: `/presence-confirmations`,
      method: HTTPMethod.PUT,
    },
    userDetails: {
      path: `/user-details`,
      method: HTTPMethod.GET,
    },
    workingHours: {
      path: `/users/working-hours`,
      method: HTTPMethod.GET,
    },
  },
};

const routingScreens = {
  Urlopia: {
    path: 'urlopia',
    screens: {
      PresenceConfirmationScreen: 'presence-confirmation',
      RequestScreen: 'request',
      ApplyRequestScreen: 'apply-request',
    },
  },
};

const config: ModuleConfig = {
  web: true,
  name: 'Urlopia',
  icon: 'calendar-alt',
  api,
  routingScreens,
};

export default config;
