import { IGetDeskDTO, IGetRoomsDTO } from './hotdesk.types';
import { call } from '@common/api';
import useApiMutation from '@common/hooks/useApiMutation';
import useApiQuery from '@common/hooks/useApiQuery';
import { showErrorToast, showSuccessToast } from '@common/services/toast';
import moment, { Moment } from 'moment';
import { useQueryClient } from 'react-query';

export enum HOTDESK_QUERY_KEYS {
  GET_ROOMS = 'getRooms',
  GET_DESKS = 'getDesks',
}

const useGetRooms = () => {
  const query = useApiQuery({
    queryKey: [HOTDESK_QUERY_KEYS.GET_ROOMS],
    queryFn: (api) => () => call<IGetRoomsDTO>(api.hotdesk.getRooms()),
    initialData: { data: [], metadata: { count: 0 } },
  });

  return {
    ...query,
    rooms: query.data?.data,
    isLoadingRooms: query.isLoading,
    placeholderData: [],
    initialData: [],
  };
};

const useGetDesks = (roomId: number, selectedDate: Moment = moment()) => {
  const selectedDateString = selectedDate.format('Y-MM-DD');
  const queryParams = {
    roomId,
    date: selectedDateString,
  };

  const query = useApiQuery({
    queryKey: [HOTDESK_QUERY_KEYS.GET_DESKS, roomId, selectedDateString],
    queryFn: (api) => () =>
      call<{ data: IGetDeskDTO[] }>(
        api.hotdesk.getDesks({ uriParams: queryParams })
      ),
    refetchOnMount: 'always',
  });

  return {
    ...query,
    desks: query.data?.data,
    isLoadingDesks: query.isLoading,
    placeholderData: [],
    initialData: [],
  };
};

const useRefreshDesks = () => {
  const queryClient = useQueryClient();
  const fn = async () => {
    await queryClient.invalidateQueries({
      queryKey: [HOTDESK_QUERY_KEYS.GET_DESKS],
    });
  };

  return {
    refreshDesks: fn,
  };
};

const useBookingDesk = () => {
  const queryClient = useQueryClient();

  const query = useApiMutation({
    mutationFn: (api) => (payload: any) =>
      call(api.hotdesk.bookDesk({ data: payload })),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [HOTDESK_QUERY_KEYS.GET_DESKS],
        });
        showSuccessToast('Biurko zostało zarezerwowane!');
      },
      onError: (error: { message: string }) => {
        showErrorToast(error.message);
      },
    },
  });

  return {
    ...query,
    bookDesk: query.mutate,
    isBookingDesk: query.isLoading,
  };
};

const useCancelingBooking = () => {
  const queryClient = useQueryClient();

  const query = useApiMutation({
    mutationFn: (api) => (id) =>
      call(api.hotdesk.cancelBooking({ uriParams: { id } })),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [HOTDESK_QUERY_KEYS.GET_DESKS],
        });
        showSuccessToast('Biurko zostało zwolnione!');
      },
      onError: (error: { message: string }) => {
        showErrorToast(error.message);
      },
    },
  });

  return {
    ...query,
    cancelBooking: query.mutate,
    isCancelingBooking: query.isLoading,
  };
};

export {
  useGetRooms,
  useBookingDesk,
  useGetDesks,
  useCancelingBooking,
  useRefreshDesks,
};
