import { pagePadding } from '../../styles/variables';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

interface ErrorMessageProps {
  title?: string;
  description?: string;
  style?: object;
}

const ErrorMessage = ({
  title = 'Wystąpił błąd',
  description = 'Nie udało się pobrać danych. Spróbuj ponownie później ',
  style,
}: ErrorMessageProps) => {
  return (
    <View style={{ ...style, ...styles.container }}>
      <View>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: pagePadding,
  },
  description: {
    textAlign: 'center',
    maxWidth: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: pagePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
  },
});

export default ErrorMessage;
