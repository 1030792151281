import RequestPayload from '../models/RequestPayload';
import React, { useState } from 'react';
import { List, RadioButton, Button } from 'react-native-paper';

type OcassionalTypProps = {
  onSubmit: (payload: RequestPayload) => void;
};

const OcassionalType: React.FC<OcassionalTypProps> = ({ onSubmit }) => {
  const [occasionalType, setOccasionalType] = useState(null);
  return (
    <>
      <List.Section>
        <RadioButton.Group
          value={occasionalType}
          onValueChange={(value: string) => setOccasionalType(value)}
        >
          <RadioButton.Item
            label='Zgon osoby bliskiej (1 dzień)'
            value='D1_FUNERAL'
          />
          <RadioButton.Item label='Ślub dziecka (1 dzień)' value='D1_WEDDING' />
          <RadioButton.Item
            label='Zgon osoby z najbliższej rodziny (2 dni)'
            value='D2_FUNERAL'
          />
          <RadioButton.Item label='Ślub  (2 dni)' value='D2_WEDDING' />
          <RadioButton.Item
            label='Narodziny dziecka (2 dni)'
            value='D2_BIRTH'
          />
        </RadioButton.Group>
      </List.Section>
      <Button mode='contained' onPress={() => onSubmit()}>
        Złóż wniosek
      </Button>
    </>
  );
};

export default OcassionalType;
