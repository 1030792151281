import AbsentList from '@modules/urlopia/components/presenceTabs/components/AbsentList';
import AbsentUser from '@modules/urlopia/models/AbsentUser';
import moment from 'moment';
import React, { useState } from 'react';
import { IconButton, List } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';

type AbsenceTabProps = {
  absentUsers: AbsentUser[];
  selectedDate: string;
  handleSelectDate: Function;
};

const AbsenceTab: React.FC<AbsenceTabProps> = ({
  absentUsers,
  selectedDate,
  handleSelectDate,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

  return (
    <>
      <List.Section>
        <List.Item
          title='Data'
          description={selectedDate}
          accessibilityLabel='WorkingDay'
          right={(props) => (
            <IconButton
              accessibilityLabel='EditAbsenceDateButton'
              {...props}
              icon='calendar'
              size={20}
              onPress={() => setOpenDatePicker(true)}
              pointerEvents='auto'
            />
          )}
        />
      </List.Section>
      <AbsentList absentUsers={absentUsers} selectedDay={selectedDate} />
      <DatePickerModal
        locale='pl'
        mode='single'
        saveLabel='Wybierz'
        visible={openDatePicker}
        onDismiss={() => {
          setOpenDatePicker(false);
        }}
        date={new Date(selectedDate)}
        onConfirm={(params) => {
          setOpenDatePicker(false);
          handleSelectDate(moment(params.date).format('Y-MM-DD'));
        }}
      />
    </>
  );
};

export default AbsenceTab;
