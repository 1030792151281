import { showMessage } from 'react-native-flash-message';

export type ErrorMessage = {
  message: string;
};

export const showErrorToast = (message: string) => {
  showMessage({
    message: 'Wystąpił błąd!',
    description: message,
    type: 'danger',
    animationDuration: 150,
    duration: 3000,
    backgroundColor: '#B3261E',
  });
};

export const showSuccessToast = (message: string) => {
  showMessage({
    message: 'Udało się!',
    description: message,
    type: 'success',
    animationDuration: 200,
    backgroundColor: '#78A612',
  });
};
