import Limit from '../components/Limit';
import MenuPicker from '../components/MenuPicker';
import MenuWithCart from '../components/MenuWithCart';
import { useGetMenus, useGetProviderLimit } from '../jajemto.hooks';
import { IGetMenusDTO } from '../jajemto.types';
import { cartItemsState } from '../state/atoms';
import Screen from '@common/components/Screen';
import { OrderScreenParamList } from '@modules/jajemto/screens/OrderScreen';
import { StackScreenProps } from '@react-navigation/stack/src/types';
import currency from 'currency.js';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useResetRecoilState } from 'recoil';

type Props = StackScreenProps<OrderScreenParamList, 'MenuScreen'>;

const MenuScreen = ({
  route: {
    params: { params },
  },
}: Props) => {
  const [index, setIndex] = useState(0);
  const [menu, setMenu] = useState<IGetMenusDTO | null>(null);
  const resetCartState = useResetRecoilState(cartItemsState);
  const { menus, isLoadingMenus, isError } = useGetMenus(params.providerId);
  const { limit, isLoadingLimit } = useGetProviderLimit(params.providerId);
  useEffect(() => {
    if (Array.isArray(menus)) {
      setMenu(menus[index]);
    }
  }, [index, menus]);

  const handleNext = () => {
    if (menus && index + 1 >= menus?.length) return;
    resetCartState();
    setIndex(index + 1);
  };

  const handlePrev = () => {
    if (index === 0) return;
    resetCartState();
    setIndex(index - 1);
  };

  const shouldShowLimitInfo = useMemo(
    () => index === 0 && moment(menu?.date).isSame(new Date(), 'day'),
    [menu, index]
  );

  return (
    <Screen loading={isLoadingMenus || isLoadingLimit} isError={isError}>
      <MenuPicker
        menus={menus || []}
        index={index}
        prev={handlePrev}
        next={handleNext}
      />
      {shouldShowLimitInfo && (
        <Limit remainingLimit={currency(limit?.remain || 0)} />
      )}
      {menu && (
        <MenuWithCart selectedMenu={menu} providerId={params.providerId} />
      )}
    </Screen>
  );
};

export default MenuScreen;
