import { pagePadding } from '../../../styles/variables';
import { useGetMenu } from '../jajemto.hooks';
import { IGetMenusDTO } from '../jajemto.types';
import CartItem from '../models/CartItem';
import Dish from '../models/Dish';
import { cartItemsState } from '../state/atoms';
import { totalCartItems } from '../state/selectors';
import CartWidget from './CartWidget';
import MenuList from './MenuList';
import ErrorMessage from '@common/components/ErrorMessage';
import Loader from '@common/components/Loader';
import { showSuccessToast } from '@common/services/toast';
import { DishCompositionWidget } from '@modules/jajemto/components/DishCompositionWidget';
import { getSelectedAdditionsSummary } from '@modules/jajemto/utils/additions';
import { useNavigation } from '@react-navigation/native';
import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Badge, Dialog, FAB, SegmentedButtons } from 'react-native-paper';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

interface MenuWithCartProps {
  selectedMenu: IGetMenusDTO;
  providerId: number;
}
const MenuWithCart = ({ selectedMenu, providerId }: MenuWithCartProps) => {
  const { menu, isLoadingMenu, isError } = useGetMenu(`${selectedMenu.id}`);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [cartItems, setCartItems] = useRecoilState(cartItemsState);
  const resetCartState = useResetRecoilState(cartItemsState);
  const cartItemsSum = useRecoilValue(totalCartItems);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { navigate } = useNavigation<{ navigate: (route: string) => void }>();

  const [isDishCompositionOpen, setIsDishCompositionOpen] = useState(false);
  const [dishUnderComposition, setDishUnderComposition] = useState<Dish>();

  const closeCartDialog = () => setIsCartOpen(false);
  const openDishCompositionDialog = () => setIsDishCompositionOpen(true);
  const closeDishCompositionDialog = () => setIsDishCompositionOpen(false);

  useEffect(() => {
    // if no items in the cart
    if (cartItems.length === 0) {
      closeCartDialog();
    }
  }, [cartItems]);

  // set default category
  useEffect(() => {
    if (menu) {
      setSelectedCategoryId(`${menu?.categories[0]?.type?.id}`);
    }
  }, [menu]);

  const handleOnSuccess = () => {
    resetCart();
    showSuccessToast('Zamówienie zostało pomyślnie złożone!');
    navigate('HistoryScreen');
  };

  const handleNewItemWithAdditions = (dish: Dish) => {
    addToCart(dish);
    closeDishCompositionDialog();
    setDishUnderComposition(undefined);
  };

  if (isError) {
    return <ErrorMessage />;
  }

  if (isLoadingMenu) {
    return <Loader />;
  }

  const categories = menu?.categories?.map((cat) => cat.type) || [];

  const category = menu?.categories?.find(
    (cat) => `${cat.type.id}` === selectedCategoryId
  );

  const handleDecreaseQuantity = (cartItem: CartItem) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === cartItem.id &&
        isEqual(cartItem.additions, item.additions) &&
        item.quantity > 1
          ? { ...item, quantity: --item.quantity }
          : item
      )
    );
  };

  const handleIncreaseQuantity = (cartItem: CartItem) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === cartItem.id && isEqual(cartItem.additions, item.additions)
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const handleRemoveFromCart = (cartItem: CartItem) => {
    setCartItems((prevCartItems) =>
      prevCartItems.filter(
        (item: CartItem) =>
          !(
            item.id === cartItem.id &&
            isEqual(cartItem.additions, item.additions)
          )
      )
    );
  };

  const addToCart = (dish: Dish) => {
    const { id, name, price, maxQuantity, addings } = dish;
    const additions = getSelectedAdditionsSummary(addings);
    const inCart = cartItems.some(
      (item: CartItem) => item.id === id && isEqual(item.additions, additions)
    );
    const cartItem = { id, name, price, maxQuantity, quantity: 1, additions };

    inCart
      ? handleIncreaseQuantity(cartItem)
      : setCartItems([...cartItems, cartItem]);
  };

  const handleNewItem = (dish: Dish) => {
    if (dish.addings.length > 0) {
      setDishUnderComposition(dish);
      openDishCompositionDialog();
    } else {
      addToCart(dish);
    }
  };

  const resetCart = () => {
    resetCartState();
    closeCartDialog();
  };

  return (
    <>
      <View style={{ minHeight: 10 }}>
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          centerContent={true}
        >
          <SegmentedButtons
            value={selectedCategoryId}
            density='small'
            style={{ marginTop: pagePadding }}
            onValueChange={setSelectedCategoryId}
            buttons={categories.map(({ id, name }) => ({
              value: `${id}`,
              label: name,
              style: { flex: 1, textAlign: 'center' },
            }))}
          />
        </ScrollView>
      </View>
      <MenuList
        dishes={category ? category.dishes : []}
        onAddToCart={handleNewItem}
      />
      {cartItems.length > 0 && (
        <View
          style={{
            position: 'absolute',
            margin: 16,
            right: 0,
            bottom: 0,
          }}
        >
          <FAB
            icon='basket-outline'
            variant='secondary'
            onPress={() => setIsCartOpen(true)}
          />
          <Badge style={{ position: 'absolute', top: -3, right: -3 }}>
            {cartItemsSum}
          </Badge>
        </View>
      )}
      <Dialog visible={isCartOpen} onDismiss={closeCartDialog}>
        <Dialog.Title style={{ fontSize: 18 }}>Twoje zamówienie:</Dialog.Title>
        <Dialog.Content>
          <CartWidget
            onRemoveItem={handleRemoveFromCart}
            onDecrease={handleDecreaseQuantity}
            onIncrease={handleIncreaseQuantity}
            onSuccess={handleOnSuccess}
            isTheOrderForToday={moment(selectedMenu.date).isSame(
              new Date(),
              'day'
            )}
            providerId={providerId}
          />
        </Dialog.Content>
      </Dialog>
      <Dialog
        visible={isDishCompositionOpen}
        onDismiss={closeDishCompositionDialog}
      >
        <Dialog.Title style={{ fontSize: 18 }}>Wybierz dodatki:</Dialog.Title>
        <Dialog.Content>
          <DishCompositionWidget
            onSelection={handleNewItemWithAdditions}
            dish={dishUnderComposition}
          />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default MenuWithCart;
