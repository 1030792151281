import { pagePadding } from '../../../styles/variables';
import {
  useGetprofileDetails,
  useGetProviderLimit,
  useMakeOrder,
} from '../jajemto.hooks';
import CartItem from '../models/CartItem';
import { cartItemsState } from '../state/atoms';
import { getAmountString } from '../utils/currency';
import { AdditionSummary } from '@modules/jajemto/utils/additions';
import currency from 'currency.js';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import {
  ActivityIndicator,
  Avatar,
  Button,
  Divider,
  IconButton,
  Text,
  useTheme,
} from 'react-native-paper';
import { useRecoilValue } from 'recoil';

interface CartProps {
  onRemoveItem: (cartItem: CartItem) => void;
  onDecrease: (cartItem: CartItem) => void;
  onIncrease: (cartItem: CartItem) => void;
  onSuccess: () => void;
  isTheOrderForToday: boolean;
  providerId: number;
}
const calculateAdditionsSummary = (
  additionsSummary: AdditionSummary[]
): currency =>
  additionsSummary.reduce(
    (prev, { price }) => currency(prev).add(price),
    currency(0)
  );

const calculateCartSummary = (
  cartItems: CartItem[],
  discountLeft: currency
) => {
  const subtotal = cartItems.reduce((prev, { price, quantity, additions }) => {
    const additionsResult = calculateAdditionsSummary(additions);
    const result = currency(price).multiply(quantity);
    return currency(prev).add(result).add(additionsResult);
  }, currency(0));

  const discountNeeded = subtotal.divide(2);
  const hasEnoughDiscount = discountNeeded.subtract(discountLeft) < currency(0);

  const discount = hasEnoughDiscount ? discountNeeded : discountLeft;
  const total = currency(subtotal).subtract(discount);

  return {
    subtotal,
    discount,
    total,
    hasEnoughDiscount,
  };
};

interface CartSummaryProps {
  summary: {
    subtotal: currency;
    discount: currency;
    total: currency;
    hasEnoughDiscount: boolean;
  };
}

const CartSummary = ({ summary }: CartSummaryProps) => {
  const theme = useTheme();
  return (
    <>
      <View style={styles.summary}>
        <Text>Razem:</Text>
        <Text>{getAmountString(summary.subtotal)}</Text>
      </View>
      <View style={styles.summary}>
        <Text>Dofinansowanie:</Text>
        <Text
          style={[!summary.hasEnoughDiscount && { color: theme.colors.error }]}
        >
          {getAmountString(summary.discount)}
        </Text>
      </View>
      <View style={styles.summary}>
        <Text variant='titleSmall'>Do zapłaty:</Text>
        <Text variant='titleSmall'>{getAmountString(summary.total)}</Text>
      </View>
    </>
  );
};

interface CartLimitProps {
  cartSummary: currency;
  remainingLimit: currency;
}

const CartLimitInfo = ({ cartSummary, remainingLimit }: CartLimitProps) => {
  const limitReached = remainingLimit === currency(0);
  const limitWillBeReached = remainingLimit.subtract(cartSummary) < currency(0);
  const limitWontBeReached = !limitReached && !limitWillBeReached;

  const icon = limitReached || limitWillBeReached ? 'check' : 'close';
  const style = limitWontBeReached && styles.limitNotReachedIcon;

  const renderText = () => {
    if (limitReached) {
      return 'Osiągnięto minimalny limit zamówień.';
    }

    return limitWillBeReached
      ? 'Po złożeniu zamówienia zostanie osięgnięty minimalny limit zamówień.'
      : `Po złożeniu zamówienia nie zostanie osięgnięty minimalny limit zamówień. Pozostało: ${getAmountString(
          remainingLimit.subtract(cartSummary)
        )}`;
  };

  return (
    <View style={styles.limitContainer}>
      <Avatar.Icon size={20} icon={icon} style={style} />
      <Text style={styles.cartLimitText}>{renderText()}</Text>
    </View>
  );
};

const CartWidget = ({
  onRemoveItem,
  onDecrease,
  onIncrease,
  onSuccess,
  isTheOrderForToday,
  providerId,
}: CartProps) => {
  const cartItems = useRecoilValue(cartItemsState);
  const { order, isLoading } = useMakeOrder();
  const { profileDetails, isLoadingProfile } = useGetprofileDetails();
  const { limit, isLoadingLimit } = useGetProviderLimit(providerId);

  if (isLoadingLimit || isLoadingProfile || isLoading) {
    return <ActivityIndicator size='small' />;
  }

  const cartSummary = calculateCartSummary(
    cartItems,
    currency(profileDetails?.account_balance?.discount_left || 0)
  );

  const handleMakeOrder = () => {
    order(
      cartItems.map(({ id, quantity, additions }) => ({
        dishId: id,
        quantity,
        forGuest: false,
        addings: additions.map((addition) => addition.id),
      })),
      {
        onSuccess: () => {
          onSuccess();
        },
      }
    );
  };

  return (
    <View style={styles.cart}>
      <View>
        <Divider style={styles.divider} />
        <ScrollView style={{ maxHeight: 200 }}>
          {cartItems.map((item, index) => (
            <View key={index} style={styles.itemContainer}>
              <View style={styles.item}>
                <View style={styles.quantityContainer}>
                  <IconButton
                    style={styles.icon}
                    icon='minus'
                    onPress={() => onDecrease(item)}
                  />

                  <Text style={styles.label}>{item.quantity}</Text>
                  <IconButton
                    style={styles.icon}
                    icon='plus'
                    onPress={() => onIncrease(item)}
                  />
                  <View style={{ flexDirection: 'column' }}>
                    <Text numberOfLines={1} style={styles.title}>
                      {item.name}
                    </Text>
                    {!!item.additions.length && (
                      <Text numberOfLines={1} style={styles.subTitle}>
                        {item.additions.map((add) => add.label).join(', ')}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              <IconButton
                style={styles.icon}
                icon='delete'
                onPress={() => onRemoveItem(item)}
              />
            </View>
          ))}
        </ScrollView>
      </View>
      <CartSummary summary={cartSummary} />
      <Button
        onPress={handleMakeOrder}
        style={styles.button}
        mode='contained'
        loading={isLoading}
      >
        Zamów
      </Button>
      {limit && isTheOrderForToday && (
        <CartLimitInfo
          cartSummary={cartSummary.total}
          remainingLimit={currency(limit?.remain || 0)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  subTitle: {
    fontSize: 12,
    maxWidth: 150,
  },
  title: {
    maxWidth: 150,
  },
  cart: {
    justifyContent: 'space-between',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  summary: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 2,
  },
  divider: {
    marginBottom: 8,
  },
  button: {
    marginTop: pagePadding,
  },
  quantityContainer: {
    height: 25,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    padding: 0,
    borderRadius: 0,
    width: 25,
    height: 25,
  },
  label: {
    fontWeight: 'bold',
  },
  limitContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  cartLimitText: {
    marginLeft: 8,
  },
  limitNotReachedIcon: {
    backgroundColor: '#FA8072',
  },
});

export default CartWidget;
