import {
  IGetDuePaymentDTO,
  IGetHistoryDTO,
  IGetMenuDTO,
  IGetMenusDTO,
  IGetProfileDetailsDTO,
  IGetProviderDTO,
  IGetProviderLimitDTO,
  IGetSettlementsDTO,
} from './jajemto.types';
import { call } from '@common/api';
import useApiMutation from '@common/hooks/useApiMutation';
import useApiQuery from '@common/hooks/useApiQuery';
import { showErrorToast, showSuccessToast } from '@common/services/toast';
import moment from 'moment';
import { useQueryClient } from 'react-query';

export enum JAJEMTO_QUERY_KEYS {
  GET_MENU = 'getMenu',
  GET_MENUS = 'getMenus',
  GET_HISTORY = 'getHistory',
  GET_SETTLEMENTS = 'getSettlements',
  GET_PROFILE = 'getProfile',
  GET_LIMIT = 'getLimit',
  GET_PROVIDERS = 'getProviders',
  GET_DUE_PAYMENT = 'getDuePayment',
}

const DEFAULT_PROVIDER_ID = 4;

const useGetProviders = () => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_PROVIDERS],
    queryFn: (api) => () => call<IGetProviderDTO[]>(api.jajemto.getProviders()),
  });

  return {
    ...query,
    providers: query.data,
    isLoadingProviders: query.isLoading,
    placeholderData: [],
    initialData: [],
  };
};

const useGetMenus = (providerId = DEFAULT_PROVIDER_ID) => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_MENUS, providerId],
    queryFn: (api) => () =>
      call<IGetMenusDTO[]>(api.jajemto.getMenus({ uriParams: { providerId } })),
  });

  return {
    ...query,
    menus: query.data,
    isLoadingMenus: query.isLoading,
    placeholderData: [],
    initialData: [],
  };
};

const useGetMenu = (menuId: string) => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_MENU, menuId],
    queryFn: (api) => () =>
      call<IGetMenuDTO>(api.jajemto.getMenu({ uriParams: { menuId } })),
    enabled: !!menuId,
  });

  return {
    ...query,
    menu: query.data?.menu || null,
    isLoadingMenu: query.isLoading,
  };
};

const useGetHistory = () => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_HISTORY],
    queryFn: (api) => () => {
      const dateFrom = moment().subtract(1, 'year').format('x');
      const deleted = 0;
      return call<IGetHistoryDTO>(
        api.jajemto.getHistory({ queryParams: { dateFrom, deleted } })
      );
    },
  });

  return {
    ...query,
    history: query.data || null,
  };
};

const useDeleteOrder = () => {
  const queryClient = useQueryClient();

  const query = useApiMutation({
    mutationFn: (api) => (id: number) => {
      if (!id) throw new Error('There is no order ID.');
      return call(api.jajemto.deleteOrder({ uriParams: { id } }));
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([JAJEMTO_QUERY_KEYS.GET_HISTORY]);
        queryClient.invalidateQueries([JAJEMTO_QUERY_KEYS.GET_PROFILE]);
        queryClient.invalidateQueries([JAJEMTO_QUERY_KEYS.GET_SETTLEMENTS]);
        showSuccessToast('Zamówienie zostało pomyślnie anulowane!');
      },
      onError: ({ message }: { message: string }) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...query,
    deleteOrder: query.mutate,
    isDeletingOrder: query.isLoading,
  };
};

const useGetSettlements = () => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_SETTLEMENTS],
    queryFn: (api) => () =>
      call<IGetSettlementsDTO>(api.jajemto.getSettlements()),
  });

  return {
    ...query,
    settlements: query.data,
    isLoadingSettlements: query.isLoading,
    isSettlementsError: query.isError,
  };
};

const useGetprofileDetails = () => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_PROFILE],
    queryFn: (api) => () =>
      call<IGetProfileDetailsDTO>(api.jajemto.getProfileDetails()),
  });

  return {
    ...query,
    profileDetails: query.data,
    isLoadingProfile: query.isLoading,
    isProfileError: query.isError,
  };
};

const useMakeOrder = () => {
  const queryClient = useQueryClient();

  const query = useApiMutation({
    mutationFn: (api) => (payload: any) =>
      call(api.jajemto.setOrder({ data: payload })),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([JAJEMTO_QUERY_KEYS.GET_HISTORY]);
        queryClient.invalidateQueries([JAJEMTO_QUERY_KEYS.GET_PROFILE]);
        queryClient.invalidateQueries([JAJEMTO_QUERY_KEYS.GET_SETTLEMENTS]);
        queryClient.invalidateQueries([JAJEMTO_QUERY_KEYS.GET_LIMIT]);
      },
      onError: ({ message }: { message: string }) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...query,
    order: query.mutate,
  };
};

const useGetDuePayment = () => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_DUE_PAYMENT],
    queryFn: (api) => () =>
      call<IGetDuePaymentDTO>(api.jajemto.getDuePayment()),
  });

  return {
    ...query,
    duePayment: query.data,
  };
};

const useGetProviderLimit = (providerId = DEFAULT_PROVIDER_ID) => {
  const query = useApiQuery({
    queryKey: [JAJEMTO_QUERY_KEYS.GET_LIMIT],
    queryFn: (api) => () =>
      call<IGetProviderLimitDTO>(
        api.jajemto.getProviderLimit({
          uriParams: { providerId },
        })
      ),
  });

  return {
    ...query,
    isLoadingLimit: query.isLoading,
    limit: query.data,
  };
};

export {
  useDeleteOrder,
  useGetDuePayment,
  useGetHistory,
  useGetMenu,
  useGetMenus,
  useGetProviderLimit,
  useGetProviders,
  useGetSettlements,
  useGetprofileDetails,
  useMakeOrder,
};
