import NormalType from '../components/NormalType';
import OcassionalType from '../components/OcassionalType';
import { getRemainingVacationTime } from '../helpers';
import {
  useAbsenceRequest,
  useGetHolidays,
  useGetPendingDays,
  useGetVacationDays,
} from '../urlopia.hooks';
import Screen from '@common/components/Screen';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { ParamListBase, useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { SegmentedButtons } from 'react-native-paper';
import { useIsFetching } from 'react-query';

interface Props {
  navigation: BottomTabNavigationProp<ParamListBase, string, undefined>;
}

const ApplyRequestScreen = ({ navigation }: Props) => {
  const loading = useIsFetching();
  const [segment, setSegment] = useState('normal');

  const { holidays } = useGetHolidays();
  const { vacationDays } = useGetVacationDays();
  const { pendingDays } = useGetPendingDays();
  const { absenceRequest } = useAbsenceRequest(navigation);

  useFocusEffect(
    useCallback(() => {
      setSegment('normal');
      return () => {};
    }, [])
  );

  return (
    <Screen loading={!!loading}>
      <SegmentedButtons
        value={segment}
        onValueChange={(value) => setSegment(value)}
        buttons={[
          {
            value: 'normal',
            label: 'Wypoczynkowy',
            style: { flex: 1 },
          },
          {
            disabled: true,
            value: 'ocassional',
            label: 'Okolicznościowy',
            style: { flex: 1 },
          },
        ]}
      />

      {segment === 'normal' ? (
        <NormalType
          remainingVacation={getRemainingVacationTime(
            vacationDays?.remainingDays - pendingDays?.pendingDays,
            vacationDays?.remainingHours
          )}
          holidays={holidays && holidays}
          onSubmit={(payload) => absenceRequest(payload)}
        />
      ) : (
        <OcassionalType onSubmit={(payload) => absenceRequest(payload)} />
      )}
    </Screen>
  );
};

export default ApplyRequestScreen;
