import { pagePadding } from '../../../styles/variables';
import Loader from '@common/components/Loader';
import Screen from '@common/components/Screen';
import { userSessionState } from '@common/state/atoms';
import RoomPreview from '@modules/hotdesk/components/RoomPreview/RoomPreview';
import { useGetRooms } from '@modules/hotdesk/hotdesk.hooks';
import { IGetDeskDTO, IGetRoomDTO } from '@modules/hotdesk/hotdesk.types';
import DeskDetails from '@modules/hotdeskAdmin/components/DeskDetails';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { SegmentedButtons, Text } from 'react-native-paper';
import { useRecoilValue } from 'recoil';

function HotdeskAdminScreen() {
  const userSession = useRecoilValue(userSessionState);

  const { rooms, isLoadingRooms } = useGetRooms();
  const [selectedDesk, setSelectedDesk] = useState<IGetDeskDTO | null>(null);

  const [activeRoom, setActiveRoom] = useState<IGetRoomDTO | null>(null);

  useEffect(() => {
    if (rooms && rooms?.length > 0) {
      setActiveRoom(rooms[0]);
    }
  }, [rooms]);

  const handleSetActiveRoom = (roomId: string) => {
    const room = rooms!.find(({ id }: { id: number }) => id === +roomId);

    if (room) {
      setActiveRoom(room);
    }
  };

  const deskPainter = (desk: IGetDeskDTO | undefined) => {
    if (desk) {
      if (desk.active) {
        if (selectedDesk && desk.id === selectedDesk!.id) {
          return '#006500';
        }
        return 'green';
      } else {
        if (selectedDesk && desk.id === selectedDesk!.id) {
          return '#555555';
        }
      }
    }

    return 'grey';
  };

  if (userSession === 'loading' || isLoadingRooms) {
    return <Loader />;
  }

  return (
    <Screen>
      <View style={styles.roomsScroll}>
        <ScrollView horizontal>
          <SegmentedButtons
            value={activeRoom?.id.toString()!}
            density='small'
            style={{ marginTop: pagePadding }}
            onValueChange={(value: string) => handleSetActiveRoom(value)}
            buttons={rooms!.map(
              ({ id, name }: { id: number; name: string }) => ({
                value: id.toString(),
                label: name,
                style: {
                  flexBasis: 'auto',
                },
              })
            )}
          />
        </ScrollView>
      </View>
      <View style={styles.container}>
        <View style={styles.halfSpace}>
          {activeRoom ? (
            <RoomPreview
              room={activeRoom!}
              handleSetSelected={setSelectedDesk}
              date={moment()}
              deskPainter={deskPainter}
            />
          ) : (
            <></>
          )}
        </View>
        <View style={styles.halfSpace}>
          {selectedDesk ? (
            <DeskDetails
              desk={selectedDesk}
              room={activeRoom!}
              resetDesk={() => setSelectedDesk(null)}
            />
          ) : (
            <Text style={styles.defaultText}>
              Kliknij na biurko, aby zmienić opcje
            </Text>
          )}
        </View>
      </View>
    </Screen>
  );
}

const styles = StyleSheet.create({
  roomsScroll: {
    marginBottom: 10,
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  halfSpace: {
    flex: 0.5,
  },
  defaultText: {
    marginTop: 20,
    textAlign: 'center',
  },
});

export default HotdeskAdminScreen;
