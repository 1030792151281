import App from './App';
import { registerRootComponent } from 'expo';
import 'react-native-gesture-handler';
import { pl, registerTranslation } from 'react-native-paper-dates';
import { RecoilRoot } from 'recoil';

registerTranslation('pl', pl);

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

function Root() {
  return (
    <RecoilRoot>
      <App />
    </RecoilRoot>
  );
}

registerRootComponent(Root);
