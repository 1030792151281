import { ApiClientContext } from '../services/apiClient';
import { showSuccessToast } from '../services/toast';
import useResetSessionState from './useResetSessionState';
import { oidc } from '@config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { useContext } from 'react';
import { Platform } from 'react-native';
import { useQueryClient } from 'react-query';

const remoteLogout = async () =>
  Platform.OS === 'web'
    ? WebBrowser.openBrowserAsync(oidc.remoteLogoutEndpoint)
    : fetch(oidc.remoteLogoutEndpoint);

const clearSettings = async () => {
  const keys = ['@theme_mode', '@working_time'];
  await AsyncStorage.multiRemove(keys);
};

export default () => {
  const clearSession = useResetSessionState();
  const queryClient = useQueryClient();
  const api = useContext(ApiClientContext);
  const navigation = useNavigation();

  return (message: string, redirectLocation = null) =>
    remoteLogout().then(() => {
      clearSession();
      queryClient.invalidateQueries();
      clearSettings();
      api.setAuth(null);
      showSuccessToast(message);
      if (redirectLocation) {
        navigation.navigate(redirectLocation);
      }
    });
};
