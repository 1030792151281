import { swipeHintState } from '@common/state/atoms';
import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { IconButton, useTheme } from 'react-native-paper';
import { useRecoilValue } from 'recoil';

const SwipeHint = () => {
  const show = useRecoilValue(swipeHintState);
  const [visible, setVisible] = useState('true');

  useEffect(() => {
    setVisible(show);
  }, [show]);

  const theme = useTheme();
  const { backdrop } = theme.colors;

  if (visible !== 'true') return null;

  return (
    <Animatable.View
      animation={'fadeIn'}
      delay={800}
      duration={200}
      style={[styles.hint, { backgroundColor: backdrop }]}
    >
      <Animatable.View
        iterationCount={'infinite'}
        animation={'fadeInRight'}
        easing={'ease-in-out'}
        duration={1200}
        delay={1000}
      >
        <IconButton
          style={{
            marginRight: 70,
          }}
          mode='contained'
          icon={'gesture-swipe-left'}
          size={20}
        />
      </Animatable.View>
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  hint: {
    position: 'absolute',
    width: '100%',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    alignItems: 'flex-end',
  },
});

export default SwipeHint;
