import moment from 'moment';

export const getPeriodLabel = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  if (start.isSame(end)) return start.format('DD.MM.YYYY');
  else if (start.isSame(end, 'month'))
    return `${start.format('DD')}-${end.format('DD.MM.YYYY')}`;
  else if (end.isSame(end, 'year'))
    return `${start.format('DD.MM')}-${end.format('DD.MM.YYYY')}`;
  else return `${start.format('DD.MM.YYYY')}-${end.format('DD.MM.YYYY')}`;
};

export const errors = {
  END_DATE_IS_BEFORE_START_DATE:
    'Data końcowa nie powinna być przed datą początkową',
  NOT_ENOUGH_DAYS: 'Nie posiadasz wystarczającej liczby dni urlopowych',
  REQUEST_OVERLAPPING:
    'Wybrany termin pokrywa się z terminem jednej z wcześniej utworzonych okoliczności',
  'This desk is already booked for this date':
    'Biurko zostało już zarezerwowane przez kogoś innego. Musisz być szybszy :)',
};

export const checkAccess = (userDetails, shoudHasRole) => {
  if (userDetails && userDetails.roles) {
    return userDetails.roles.includes(shoudHasRole);
  }
  return false;
};

export const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const minutes = ['00', '10', '20', '30', '40', '50'];

export const getDaysLabel = (days) => {
  if (days === 1 && days !== 11 && days.toString()[1] !== '1') {
    return `${days} dzień`;
  }
  return `${days} dni`;
};

export const getRemainingVacationTime = (
  remainingDays = 0,
  remainingHours = 0
) => {
  if (remainingHours === 0) {
    return getDaysLabel(remainingDays);
  }
  return `${remainingDays}d ${remainingHours}h`;
};
export const format = 'YYYY-MM-DD';
