import { ADGroups } from '@common/hooks/useIsMemberOf';
import { UserSessionValue } from '@common/state/atoms';
import { oidc } from '@config';
import { TokenResponse, refreshAsync } from 'expo-auth-session';
import { TokenResponseConfig } from 'expo-auth-session/src/TokenRequest.types';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { SetterOrUpdater } from 'recoil';

interface User {
  email: string;
  givenName: string;
  familyName: string;
  groups: string[];
}
export type UserSession = User & TokenResponseConfig;

export type Auth = {
  getAccessToken: () => string;
  getIdToken: () => string | undefined;
  getRefreshToken: () => string | undefined;
  shouldRefresh: () => boolean;
  refreshSession: () => Promise<void>;
  isMemberOf: (group: ADGroups) => boolean;
};

type AuthParams = {
  userSession: UserSession;
  setUserSession: SetterOrUpdater<UserSessionValue>;
  logout: (message: string) => Promise<void>;
};

interface IDTokenPayload extends JwtPayload {
  email: string;
  given_name: string;
  family_name: string;
  groups: string[];
}

const getUser: (idToken: string) => User = (idToken: string) => {
  const payload: IDTokenPayload = jwt_decode<IDTokenPayload>(idToken);
  const {
    email,
    given_name: givenName,
    family_name: familyName,
    groups,
  } = payload;
  return { email, givenName, familyName, groups };
};

export const getUserSession: (tokensResponse: TokenResponse) => UserSession = (
  tokensResponse: TokenResponse
) => {
  if (!tokensResponse.idToken) {
    throw Error('The ID token is missing.');
  }
  const user: User = getUser(tokensResponse.idToken);
  const { idToken, refreshToken, accessToken, expiresIn, issuedAt } =
    tokensResponse;

  return { ...user, idToken, refreshToken, accessToken, expiresIn, issuedAt };
};

export default ({ userSession, setUserSession, logout }: AuthParams): Auth => {
  let session = { ...userSession };
  const refreshSession = async () => {
    try {
      const tokensResponse = await refreshAsync(
        {
          clientId: oidc.clientId,
          refreshToken: session.refreshToken,
        },
        { tokenEndpoint: oidc.tokenEndpoint }
      );
      session = getUserSession(tokensResponse);
      setUserSession(session);
    } catch (error) {
      logout('Twoja sesja wygasła');
    }
  };

  return {
    getAccessToken: () => session.accessToken,
    getIdToken: () => session.idToken,
    getRefreshToken: () => session.refreshToken,
    shouldRefresh: () =>
      !TokenResponse.isTokenFresh({
        expiresIn: session.expiresIn,
        issuedAt: session.issuedAt,
      }),
    refreshSession,
    isMemberOf: (group) => session.groups.indexOf(group) !== -1,
  };
};
