import Additions from '@modules/jajemto/models/Additions';

export interface AdditionSummary {
  id: number;
  price: number;
  label: string;
}

export const getSelectedAdditionsSummary = (
  additions: Additions[]
): AdditionSummary[] => {
  return additions.reduce<AdditionSummary[]>((summary, addition) => {
    const checkedItem = addition.options.find((option) => option.checked);
    if (checkedItem) {
      summary.push({
        id: checkedItem.id,
        label: checkedItem.name,
        price: checkedItem.price,
      });
    }
    return summary;
  }, []);
};
