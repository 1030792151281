import MenuScreen from '@modules/jajemto/screens/MenuScreen';
import ProvidersScreen from '@modules/jajemto/screens/ProvidersScreen';
import { createStackNavigator } from '@react-navigation/stack';

export type OrderScreenParamList = {
  MenuScreen: { params: { providerId: number } };
  ProvidersScreen: undefined;
};

const Stack = createStackNavigator<OrderScreenParamList>();

const OrderScreen = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name='ProvidersScreen' component={ProvidersScreen} />
      <Stack.Screen name='MenuScreen' component={MenuScreen} />
    </Stack.Navigator>
  );
};

export default OrderScreen;
