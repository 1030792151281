import { cartItemsState } from './atoms';
import { selector } from 'recoil';

export const totalCartItems = selector({
  key: 'TotalCartItems',
  get: ({ get }) => {
    const items = get(cartItemsState);

    return items.reduce((sum, { quantity }) => {
      return sum + quantity;
    }, 0);
  },
});
