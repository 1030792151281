import { checkAccess } from './helpers';
import Roles from './models/Roles';
import ApplyRequestScreen from './screens/ApplyRequestScreen';
import PresenceConfirmationScreen from './screens/PresenceConfirmationScreen';
import RequestScreen from './screens/RequestScreen';
import { useGetPendingAcceptances, useGetUserDetails } from './urlopia.hooks';
import Header from '@common/components/Header';
import Loader from '@common/components/Loader';
import { Navigation } from '@common/components/Navigation';
import { BottomTabHeaderProps } from '@react-navigation/bottom-tabs';
import React from 'react';

function UrlopiaModule() {
  const { userDetails } = useGetUserDetails();

  const isLeader = checkAccess(userDetails, Roles.leader);

  const { acceptances } = useGetPendingAcceptances(isLeader);

  if (!userDetails || (!acceptances && isLeader)) return <Loader />;

  const getBadgeLabel = () => {
    if (!acceptances || acceptances.content.length === 0) return undefined;
    if (acceptances.content.length > 9) return '9+';

    return acceptances.content.length;
  };

  return (
    <Navigation
      header={(props: BottomTabHeaderProps) => (
        <Header {...props} accessibilityLabel='UrlopiaPage' />
      )}
      routes={[
        {
          name: 'PresenceConfirmationScreen',
          title: 'Obecności',
          icon: 'account-check',
          renderComponent: () => (
            <PresenceConfirmationScreen
              ec={userDetails.ec}
              userId={userDetails.userId}
            />
          ),
        },
        {
          name: 'RequestScreen',
          title: 'Moje wnioski',
          icon: 'history',
          badge: getBadgeLabel(),
          renderComponent: () => <RequestScreen />,
        },
        {
          name: 'ApplyRequestScreen',
          title: 'Nowy wniosek',
          icon: 'plus',
          renderComponent: ({ navigation }) => (
            <ApplyRequestScreen navigation={navigation} />
          ),
        },
      ]}
    />
  );
}

export default UrlopiaModule;
