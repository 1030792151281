import { USER_SESSION_LOADING_STATE, userSessionState } from '../state/atoms';
import useApi from '@common/hooks/useApi';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

interface UserSessionLoaderProps {
  hideSplashScreen: () => void;
  children: React.ReactElement;
}

const UserSessionGuard = ({
  children,
  hideSplashScreen,
}: UserSessionLoaderProps) => {
  const api = useApi();
  const userSession = useRecoilValue(userSessionState);

  useEffect(() => {
    if (api?.getAuth()?.shouldRefresh()) {
      api.getAuth()?.refreshSession();
    }
  }, [api]);

  useEffect(() => {
    if (userSession !== USER_SESSION_LOADING_STATE) {
      hideSplashScreen();
    }
  }, [userSession]);

  return userSession === USER_SESSION_LOADING_STATE ? null : children;
};

export default UserSessionGuard;
