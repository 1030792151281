import Balkonowa from '@modules/hotdesk/rooms/Balkonowa';
import Balowa from '@modules/hotdesk/rooms/Balowa';
import Support1 from '@modules/hotdesk/rooms/Support1';
import Support2 from '@modules/hotdesk/rooms/Support2';
import React from 'react';
import { Text, View } from 'react-native';

interface IRooms {
  [key: string]: Function;
}

const roomsComponents: IRooms = { Balowa, Balkonowa, Support1, Support2 };

const Room: React.Component = (props: {
  name: string;
  handleOnPress: Function;
  deskPainter: Function;
}) => {
  const { name } = props;

  if (!roomsComponents[name])
    return (
      <View>
        <Text>Aj em sorry... Dis rum prewiew is not implementet jet</Text>
      </View>
    );
  const TheRoom = roomsComponents[name];

  return <TheRoom {...props} />;
};

export default Room;
