import BookDeskModal from '@modules/hotdesk/components/BookDeskModal';
import { IGetDeskDTO, IGetRoomDTO } from '@modules/hotdesk/hotdesk.types';
import moment, { Moment } from 'moment';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-paper';

type ManageBookingModalProps = {
  desk: IGetDeskDTO;
  isOwnBooking: boolean;
  onDismiss: () => void;
  cancelBooking: () => void;
  isCancelingBooking: boolean;
  activeRoom: IGetRoomDTO | null;
  selectedDay: Moment;
};
function ManageBookingModal({
  desk,
  isOwnBooking,
  onDismiss,
  cancelBooking,
  isCancelingBooking,
  activeRoom,
  selectedDay,
}: ManageBookingModalProps) {
  const isPast = selectedDay.endOf('day').isBefore(moment());
  const Actions = () =>
    isOwnBooking ? (
      <>
        <Button onPress={onDismiss}>Wyjdź</Button>
        {isPast ? (
          <></>
        ) : (
          <Button
            onPress={cancelBooking}
            mode='contained'
            loading={isCancelingBooking}
          >
            Anuluj rezerwacje
          </Button>
        )}
      </>
    ) : (
      <Button onPress={onDismiss}>Wyjdź</Button>
    );

  const Content = () => (
    <Text>
      Biurko
      <Text style={styles.bold}> {desk?.name} </Text>w sali
      <Text style={styles.bold}> {activeRoom?.name}</Text>, na dzień
      <Text style={styles.bold}> {selectedDay.format('D MMMM (dddd)')} </Text>
      zostało zarezerwowane przez
      <Text style={styles.bold}> {desk?.reservations[0].userDisplayName}</Text>.
    </Text>
  );

  return (
    <BookDeskModal
      visible={!!desk}
      onDismiss={onDismiss}
      title='Rezerwacja'
      actions={<Actions />}
    >
      {<Content />}
    </BookDeskModal>
  );
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
});

export default ManageBookingModal;
