import { ApiConfig, EnvPrefixes, ModuleConfig } from '../../global.types';
import { HTTPMethod } from '@common/enums/Http';
import { ADGroups } from '@common/hooks/useIsMemberOf';

export type HotdeskAdminApi = {};

const envPrefixes: EnvPrefixes = {
  development: '/hotdesk-dev/v1',
  production: '/hotdesk/v1',
};

const api: ApiConfig<HotdeskAdminApi> = {
  baseUrl: 'https://apigateway.fingo.info',
  envPrefixes,
  endpoints: {
    updateDesk: {
      path: `/desks/{id}`,
      method: HTTPMethod.PATCH,
    },
  },
};

const routingScreens = {
  HotdeskAdmin: {
    path: 'hotdesk-admin',
    screens: {
      HotdeskAdminScreen: '',
    },
  },
};

const config: ModuleConfig = {
  web: true,
  mobile: false,
  name: 'HotdeskAdmin',
  icon: 'fire',
  api,
  routingScreens,
  requiredRole: [ADGroups.HOTDESK_ADMIN, ADGroups.HOTDESK_ADMIN2],
};

export default config;
