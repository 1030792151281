import { useGetDesks } from '../../hotdesk.hooks';
import { IGetRoomDTO } from '../../hotdesk.types';
import Loader from '@common/components/Loader';
import Room from '@modules/hotdesk/components/RoomPreview/Room';
import RoomPreviewView from '@modules/hotdesk/components/RoomPreview/RoomPreviewView';
import { Moment } from 'moment';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

const RoomPreview = ({
  room,
  handleSetSelected,
  date,
  deskPainter,
}: {
  room: IGetRoomDTO;
  handleSetSelected: Function;
  date: Moment;
  deskPainter: Function;
}) => {
  const { desks, isLoadingDesks } = useGetDesks(room?.id, date);

  const handleSetSelectedDesk = (deskId: string) => {
    const selectedDesk = desks!.find(
      ({ name }: { name: string }) => name === deskId
    );
    if (!selectedDesk) return;

    handleSetSelected(selectedDesk);
  };

  const colourDesk = (deskId: string) =>
    deskPainter(desks!.find(({ name }: { name: string }) => name === deskId));

  if (isLoadingDesks) return <Loader />;

  return (
    <View style={styles.roomPreview}>
      <RoomPreviewView>
        <Room
          name={room.name}
          handleOnPress={handleSetSelectedDesk}
          deskPainter={colourDesk}
        />
      </RoomPreviewView>
    </View>
  );
};

const styles = StyleSheet.create({
  roomPreview: {
    ...Platform.select({
      web: {
        maxHeight: '80vh',
      },
      default: {
        flex: 1,
      },
    }),
  },
});

export default RoomPreview;
