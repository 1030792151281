import {
  ApiConfig,
  EndpointConfig,
  EnvPrefixes,
  ModuleConfig,
} from '../../global.types';
import { HTTPMethod } from '@common/enums/Http';

export type KontaktyApi = {
  getEmergencyContacts: EndpointConfig;
};

const envPrefixes: EnvPrefixes = {
  development: '/static',
  production: '/static',
};

const api: ApiConfig<KontaktyApi> = {
  baseUrl: 'https://apigateway.fingo.info',
  envPrefixes,
  endpoints: {
    getEmergencyContacts: {
      path: `/emergency-contacts`,
      method: HTTPMethod.GET,
    },
  },
};

const routingScreens = {
  Kontakty: {
    path: 'kontakty',
    screens: {
      KontaktyScreen: '',
    },
  },
};

const config: ModuleConfig = {
  web: true,
  name: 'Kontakty',
  icon: 'phone-square',
  api,
  routingScreens,
};

export default config;
