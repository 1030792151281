import { format } from '@modules/urlopia/helpers';
import Holiday from '@modules/urlopia/models/Holiday';
import { Moment } from 'moment';

export default function (date: Moment, holidays: Holiday[]): boolean {
  const mapped = holidays ? holidays.map((item) => item.date) : [];

  return (
    date.isoWeekday() === 6 ||
    date.isoWeekday() === 7 ||
    mapped.includes(date.format(format))
  );
}
