import HotdeskScreen from './screens/HotdeskScreen';
import Header from '@common/components/Header';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import moment from 'moment/moment';
import React from 'react';
import { Platform } from 'react-native';

export type HotDeskParamList = {
  HotdeskScreen: { selectedDay: string };
};

const Stack = createStackNavigator<HotDeskParamList>();

function HotdeskModule() {
  const cardStyleInterpolator =
    Platform.OS === 'android'
      ? CardStyleInterpolators.forFadeFromBottomAndroid
      : CardStyleInterpolators.forHorizontalIOS;

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='HotdeskScreen'
        options={{
          header: (props) => <Header {...props} />,
          title: 'Hotdesk',
          cardStyleInterpolator,
        }}
        initialParams={{
          selectedDay: moment().format('YYYY-MM-DD'),
        }}
        component={HotdeskScreen}
      />
    </Stack.Navigator>
  );
}

export default HotdeskModule;
