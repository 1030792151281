import { pagePadding } from '../../../styles/variables';
import { IGetProfileDetailsDTO, ISettlementDTO } from '../jajemto.types';
import { getAmountString } from '../utils/currency';
import CommonDivider from '@common/components/CommonDivider';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import {
  Chip,
  DataTable,
  List,
  Surface,
  Text,
  useTheme,
} from 'react-native-paper';

interface ISettlementProps {
  profile: IGetProfileDetailsDTO | undefined;
  settlements: ISettlementDTO[];
}

const Settlement = ({ settlements, profile }: ISettlementProps) => {
  const perPage = 8;
  const theme = useTheme();
  const { surface } = theme.colors;

  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = useState(perPage);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, settlements.length);

  useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage]);

  if (settlements.length === 0) {
    return (
      <Surface>
        <Text style={styles.empty}>
          Nie złożyłeś jeszcze żadnego zamówienia!
        </Text>
      </Surface>
    );
  }

  return (
    <>
      <List.Section>
        <List.Item
          title='Saldo'
          right={() => (
            <Chip mode='outlined' icon='calendar'>
              {getAmountString(profile?.account_balance?.to_pay || 0)}
            </Chip>
          )}
        />
        <List.Item
          title='Dofinansowanie'
          right={() => (
            <Chip mode='outlined' icon='calendar'>
              {getAmountString(profile?.account_balance?.discount_left || 0)}
            </Chip>
          )}
        />
      </List.Section>

      <CommonDivider />
      <ScrollView>
        <Surface>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Okres</DataTable.Title>
              <DataTable.Title>Razem</DataTable.Title>
              <DataTable.Title numeric>Zapłacono</DataTable.Title>
              <DataTable.Title numeric>Do zapłaty</DataTable.Title>
            </DataTable.Header>

            {settlements
              .slice(from, to)
              .map(({ period, orders_total, paid_total, to_pay }) => {
                return (
                  <DataTable.Row
                    style={{ backgroundColor: surface }}
                    key={period}
                  >
                    <DataTable.Cell>{period}</DataTable.Cell>
                    <DataTable.Cell>
                      {getAmountString(orders_total)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric>
                      {getAmountString(paid_total)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric>
                      {getAmountString(to_pay)}
                    </DataTable.Cell>
                  </DataTable.Row>
                );
              })}
            <>
              {settlements.length > perPage && (
                <DataTable.Pagination
                  page={page}
                  numberOfPages={Math.ceil(
                    settlements.length / numberOfItemsPerPage
                  )}
                  onPageChange={(nextPage) => setPage(nextPage)}
                  label={`${from + 1}-${to} na ${settlements.length}`}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                />
              )}
            </>
          </DataTable>
        </Surface>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  action: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    minWidth: 70,
  },
  icon: {
    marginTop: 10,
  },
  empty: {
    textAlign: 'center',
    marginVertical: pagePadding,
  },
});

export default Settlement;
