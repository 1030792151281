import { pagePadding } from '../../../../../styles/variables';
import CommonDivider from '@common/components/CommonDivider';
import AbsentUser from '@modules/urlopia/models/AbsentUser';
import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { List, Surface, Text } from 'react-native-paper';

const AbsentList = ({
  absentUsers,
  selectedDay,
}: {
  absentUsers: AbsentUser[];
  selectedDay: string;
}) => {
  return (
    <>
      <Text variant='titleMedium'>Nieobecni w dniu: {selectedDay}</Text>
      <CommonDivider />
      <ScrollView>
        {absentUsers.length ? (
          absentUsers.map((item, index) => {
            return <List.Item key={index} title={item.userName} />;
          })
        ) : (
          <Surface>
            <Text style={styles.empty}>Wszyscy są w pracy</Text>
          </Surface>
        )}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  empty: {
    textAlign: 'center',
    marginVertical: pagePadding,
  },
});

export default AbsentList;
