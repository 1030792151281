import {
  ApiConfig,
  EndpointConfig,
  EnvPrefixes,
  ModuleConfig,
} from '../../global.types';
import { HTTPMethod } from '@common/enums/Http';

export type JaJemToApi = {
  getMenu: EndpointConfig;
  getHistory: EndpointConfig;
  getSettlements: EndpointConfig;
  deleteOrder: EndpointConfig;
  getMenus: EndpointConfig;
  setOrder: EndpointConfig;
  getProfileDetails: EndpointConfig;
  getDuePayment: EndpointConfig;
  getProviderLimit: EndpointConfig;
  getProviders: EndpointConfig;
};

const envPrefixes: EnvPrefixes = {
  development: '/jajemto-stage/v1',
  production: '/jajemto/v1',
};

const api: ApiConfig<JaJemToApi> = {
  baseUrl: 'https://apigateway.fingo.info',
  envPrefixes,
  endpoints: {
    getMenu: {
      path: `/menu/show/{menuId}`,
      method: HTTPMethod.GET,
    },
    getHistory: {
      path: `/user/orders`,
      method: HTTPMethod.GET,
    },
    getSettlements: {
      path: `/settlements/list`,
      method: HTTPMethod.GET,
    },
    deleteOrder: {
      path: `/order/{id}`,
      method: HTTPMethod.DELETE,
    },
    getMenus: {
      path: `/menu/{providerId}`,
      method: HTTPMethod.GET,
    },
    setOrder: {
      path: `/order/save`,
      method: HTTPMethod.POST,
    },
    getProfileDetails: {
      path: `/account/details`,
      method: HTTPMethod.GET,
    },
    getDuePayment: {
      path: `/settlements/due-payment`,
      method: HTTPMethod.GET,
    },
    getProviderLimit: {
      path: `/provider/{providerId}/limit`,
      method: HTTPMethod.GET,
    },
    getProviders: {
      path: `/providers`,
      method: HTTPMethod.GET,
    },
  },
};

const routingScreens = {
  Jajemto: {
    path: 'jajemto',
    screens: {
      MenuScreen: 'menu',
      HistoryScreen: 'history',
      SettlementScreen: 'settlement',
    },
  },
};

const config: ModuleConfig = {
  web: true,
  name: 'Jajemto',
  icon: 'utensils',
  api,
  routingScreens,
};

export default config;
