import Screen from '@common/components/Screen';
import useApi from '@common/hooks/useApi';
import useLogout from '@common/hooks/useLogout';
import { isProductionBuild } from '@common/services/appContext';
import { themeModeState, userSessionState } from '@common/state/atoms';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import { Platform, StyleSheet, Switch, View } from 'react-native';
import { Button, List, Text } from 'react-native-paper';
import { useRecoilState, useRecoilValue } from 'recoil';

const SettingsScreen = () => {
  const [themeMode, setThemeMode] = useRecoilState(themeModeState);
  const logout = useLogout();
  const api = useApi();
  const userSession = useRecoilValue(userSessionState);

  const getVersion = () => {
    // ios / android
    if (Application.nativeBuildVersion) {
      return Application.nativeBuildVersion;
    }
    // web
    return Constants.expoConfig?.version;
  };

  return (
    <Screen customStyle={styles.container}>
      <List.Section accessibilityLabel='SettingsPage'>
        <List.Item
          title='Tryb ciemny'
          accessibilityLabel={themeMode}
          right={() => (
            <Switch
              {...(Platform.OS === 'web' ? { pointerEvents: 'box-none' } : {})}
              value={themeMode === 'dark'}
              onValueChange={() => {
                setThemeMode(themeMode === 'dark' ? 'light' : 'dark');
              }}
            />
          )}
        />
      </List.Section>
      <View>
        <View style={styles.versionNumber}>
          <Text style={styles.textCenter}>{userSession?.email}</Text>
          <Text style={styles.textCenter}>
            ver: {getVersion()}, API: {api.getEnvName()}, build:{' '}
            {isProductionBuild() ? 'production' : 'local'}
          </Text>
        </View>
        <Button
          icon='logout'
          accessibilityLabel='LogoutButton'
          onPress={() => logout('Pomyślnie wylogowano użytkownika')}
          mode='contained-tonal'
        >
          Wyloguj
        </Button>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  versionNumber: {
    marginTop: 'auto',
    marginBottom: '2%',
    margin: 'auto',
    opacity: 0.3,
  },
  textCenter: {
    textAlign: 'center',
  },
});

export default SettingsScreen;
