import { pagePadding } from '../../../styles/variables';
import Confirmation from '../models/Confirmation';
import ConfirmationPayload from '../models/ConfirmationPayload';
import AbsenceTab from '@modules/urlopia/components/presenceTabs/AbsenceTab';
import PresenceTab from '@modules/urlopia/components/presenceTabs/PresenceTab';
import AbsentUser from '@modules/urlopia/models/AbsentUser';
import Holiday from '@modules/urlopia/models/Holiday';
import Vacations from '@modules/urlopia/models/Vacations';
import WorkingHours from '@modules/urlopia/models/WorkingHours';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SegmentedButtons } from 'react-native-paper';

type PresenceConfirmationProps = {
  selectedTab: string;
  handleSelectedTab: Function;
  userId: number;
  confirmations: Confirmation[] | undefined;
  holidays: Holiday[] | undefined;
  vacations: Vacations | undefined;
  onConfirm: (payload: ConfirmationPayload) => void;
  absentUsers: AbsentUser[];
  selectedDate: string;
  handleSelectDate: Function;
  isEC: boolean;
  workingHours: WorkingHours;
};

const PresenceConfirmation: React.FC<PresenceConfirmationProps> = ({
  selectedTab,
  handleSelectedTab,
  userId,
  confirmations = [],
  holidays = [],
  vacations = {},
  onConfirm,
  absentUsers = [],
  selectedDate,
  handleSelectDate,
  isEC,
  workingHours,
}) => {
  return (
    <View>
      {isEC && (
        <SegmentedButtons
          value={selectedTab}
          onValueChange={(data) => {
            handleSelectedTab(data);
          }}
          buttons={[
            {
              value: 'presence',
              icon: 'check',
              label: 'Lista obecności',
              style: styles.segment,
            },
            {
              value: 'absent',
              icon: 'calendar-month',
              label: 'Nieobecni',
              style: styles.segment,
            },
          ]}
        />
      )}
      {selectedTab === 'presence' ? (
        <PresenceTab
          userId={userId}
          confirmations={confirmations}
          onConfirm={onConfirm}
          holidays={holidays}
          vacations={vacations}
          workingHours={workingHours}
        />
      ) : (
        <AbsenceTab
          absentUsers={absentUsers}
          selectedDate={selectedDate}
          handleSelectDate={handleSelectDate}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  segment: {
    flex: 1,
  },
  button: {
    marginTop: pagePadding,
  },
});

export default PresenceConfirmation;
