import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Dialog } from 'react-native-paper';

function Modal({
  visible,
  onDismiss,
  actions,
  title,
  children,
  turnOffDialogAnimation = false,
}: {
  visible: boolean;
  onDismiss: () => void;
  actions: JSX.Element;
  title: string;
  children: JSX.Element;
  turnOffDialogAnimation?: boolean;
}) {
  const [themeOverride, setThemeOverride] = useState({});

  useEffect(() => {
    if (turnOffDialogAnimation) {
      setThemeOverride({
        animation: {
          scale: 0,
        },
      });
    }
  }, [turnOffDialogAnimation]);

  return (
    <Dialog
      style={styles.dialog}
      visible={visible}
      onDismiss={onDismiss}
      theme={themeOverride}
    >
      {title && <Dialog.Title>{title}</Dialog.Title>}
      <Dialog.Content>{children}</Dialog.Content>
      {actions && <Dialog.Actions>{actions}</Dialog.Actions>}
    </Dialog>
  );
}

const styles = StyleSheet.create({
  dialog: {
    ...Platform.select({
      web: {
        marginLeft: '25%',
        maxWidth: '50%',
        minWidth: '300px',
      },
      default: {},
    }),
  },
});

export default Modal;
