import { USER_SESSION_LOADING_STATE, userSessionState } from '../state/atoms';
import { useRecoilValue } from 'recoil';

export enum ADGroups {
  URLOPIA = '49bd77ca-3745-45a6-928b-7dc392842f55', //UrlopiaTest
  CONTRACTOR = '1bffd976-a3d8-42f1-9c72-49f891bdf512', //B2BUrlopiatest
  EMPLOYEE = 'b087a39b-5ae0-4434-b699-a3b855c5b6ac', //ECUrlopiatest
  URLOPIA_ADMIN = '665dd3c7-244b-4f20-9134-f3ec0a29369e', //urlopia_admins_test
  JAJEMTO_USER = '5bb6c28b-f4bb-46a5-b2bc-3c22152a4490', //jajemto-users
  JAJEMTO_ADMIN = '0af3db0b-51d9-4181-a147-1272f58125b3', //jajemto-administrators
  JAJEMTO_MANAGER = '6a7e675e-499a-400c-9421-4df68d88d360', //jajemto-managers
  HOTDESK_USER = '26ae5db5-8b51-4e53-85cf-13331f3ebbbb', //hotdesk-booking
  HOTDESK_USER2 = 'f11d78d6-cb9f-494a-8dde-7f10d5262f40', //hotdesk-booking
  HOTDESK_ADMIN = 'd11b9104-7d79-4b44-a448-4b545fdac006', //hotdesk-admin
  HOTDESK_ADMIN2 = 'd76beac3-bc91-4eb6-8c4a-2874189f9de4', //hotdesk-admin
  MOBILE_TESTER = 'ef953c07-a276-45b9-b2f4-ffeb03efc966', //mobile-testers
}

type IsMemberOf = (group: ADGroups) => boolean;

export default (): IsMemberOf => {
  const userSession = useRecoilValue(userSessionState);

  const isMemberOf: IsMemberOf = (group: ADGroups) =>
    userSession && userSession !== USER_SESSION_LOADING_STATE
      ? userSession.groups.indexOf(group) !== -1
      : false;

  return isMemberOf;
};
