import { pagePadding } from '../../../styles/variables';
import { useFindDrivers } from '../parking.hooks';
import Screen from '@common/components/Screen';
import { call } from '@common/utils/call';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Chip, Text } from 'react-native-paper';
import { List, TextInput } from 'react-native-paper';
import Icon from 'react-native-vector-icons/FontAwesome5';

function DriversScreen() {
  const { isDriversLoading, drivers } = useFindDrivers();

  const [search, setSearch] = useState<string>('');

  const cars = drivers?.drivers || [];

  const filteredCars = search
    ? cars.filter((car) => {
        return (
          car.plateNumber.includes(search.toLocaleUpperCase()) ||
          car.drivers.filter((driver) =>
            driver.name.toLocaleUpperCase().includes(search.toLocaleUpperCase())
          ).length
        );
      })
    : cars;

  return (
    <Screen loading={isDriversLoading}>
      <View accessibilityLabel='ParkingPage' style={styles.row}>
        <TextInput
          label='Nr rejestracyjny / imię / nazwisko'
          value={search}
          onChangeText={(text) => setSearch(text)}
          right={<TextInput.Icon icon='close' onPress={() => setSearch('')} />}
        />

        <Text style={styles.hintText}>
          <Icon name='phone' /> Kliknij na kierowcę, aby zadzwonić.
        </Text>
      </View>

      <ScrollView>
        <List.Section>
          {filteredCars.map((car) =>
            car.drivers.map((driver) => (
              <List.Item
                accessibilityLabel='DriversList'
                key={`car-${car.plateNumber}-${driver.slackName}`}
                titleEllipsizeMode='middle'
                title={driver.name}
                onPress={() => call(driver.phone.replaceAll(/\s/g, ''))}
                left={(props) => <Chip icon='car'>{car.plateNumber}</Chip>}
              />
            ))
          )}
        </List.Section>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  driversColumn: {
    textAlign: 'right',
  },
  picture: {
    width: '100%',
    height: 'auto',
    aspectRatio: 1.77,
  },
  row: {
    marginVertical: pagePadding,
  },
  column: {
    flex: 1,
  },
  gap: {
    width: pagePadding,
  },
  hintText: {
    marginTop: 20,
  },
});

export default DriversScreen;
