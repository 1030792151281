import { pagePadding } from '../../../styles/variables';
import Dish from '../models/Dish';
import MenuItem from './MenuItem';
import React from 'react';
import { StyleSheet, FlatList } from 'react-native';

type MenuListProps = {
  dishes: Dish[];
  onAddToCart: (item: Dish) => void;
};

const MenuList: React.FC<MenuListProps> = ({ dishes, onAddToCart }) => {
  return (
    <FlatList
      style={styles.list}
      data={dishes}
      renderItem={(props) => (
        <MenuItem {...props} length={dishes.length} onAddToCart={onAddToCart} />
      )}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    backgroundColor: 'transparent',
    flex: 1,
    marginTop: pagePadding,
  },
});

export default MenuList;
