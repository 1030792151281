import { ApiConfig, EndpointConfig, EnvPrefixes } from '../../global.types';
import { Auth } from './auth';
import { Env } from '@common/enums/Env';
import { HTTPMethod } from '@common/enums/Http';
import { ADGroups } from '@common/hooks/useIsMemberOf';
import { modules, apiDefaultTimeout, Modules } from '@config';
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { isNull } from 'lodash';
import { createContext } from 'react';

export type ApiClientType = {
  setAuth: (authService: any) => void;
  isAuth: () => boolean;
  getAuth: () => Auth | null;
  getEnvName: () => string;
} & CallableModulesEndpoints;

interface Params {
  [key: string]: any;
}

type RequestParams = {
  url: string;
  uriParams: Params;
  queryParams: Params;
  method: string;
  data: Params;
  timeout: number;
  options: Params;
};

type EndpointParams = {
  uriParams?: object;
  queryParams?: object;
  data?: object;
  options?: object;
};

type CallableModule = {
  [key: string]: (params?: EndpointParams) => AxiosPromise;
};

type CallableModulesEndpoints = Record<Partial<Modules>, CallableModule>;

// A little bit of magic, but we want to be able to call endpoints based on configuration
// Example: apiClient.urlopia.session({...}).then().catch();
const apiClient = (authService: Auth | null = null): ApiClientType => {
  let auth = authService;

  const setAuth = (a: Auth): void => {
    auth = a;
  };

  const getAuth = (): Auth | null => auth;

  const isAuth = () => !isNull(auth);

  const getQueryString = (queryParams: Params) =>
    Object.keys(queryParams)
      .map(
        (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k])
      )
      .join('&');

  const replaceUrlParams = (url: string, params: Params): string => {
    let newUrl = url;
    Object.keys(params).forEach(
      (paramKey) => (newUrl = newUrl.replace(`{${paramKey}}`, params[paramKey]))
    );
    return newUrl;
  };

  const request = async ({
    url,
    uriParams = {},
    queryParams = {},
    method = 'GET',
    data = {},
    timeout = apiDefaultTimeout,
    options = {},
  }: RequestParams): AxiosPromise => {
    const requestUrl = replaceUrlParams(url, uriParams);
    const queryString = getQueryString(queryParams);

    if (auth && auth.shouldRefresh()) {
      await auth.refreshSession();
    }

    const requestConfig: AxiosRequestConfig = {
      method,
      url: `${requestUrl}${queryString.length ? '?' + queryString : ''}`,
      timeout,
      ...options,
    };

    if (auth) {
      requestConfig.headers = {
        Authorization: `Bearer ${auth.getAccessToken()}`,
      };
    }

    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      requestConfig.data = data;
    }

    return axios(requestConfig);
  };

  const getEnvName = () =>
    auth?.isMemberOf(ADGroups.MOBILE_TESTER) ? Env.DEVELOPMENT : Env.PRODUCTION;

  const getCallableModulesEndpoints: () => CallableModulesEndpoints = () =>
    (Object.keys(modules) as Array<Modules>).reduce((acc, module) => {
      if (modules[module].hasOwnProperty('api')) {
        acc[module] = getCallableModule(modules[module].api);
      }
      return acc;
    }, {} as CallableModulesEndpoints);

  const getCallableModule: (
    config: ApiConfig<{ [key: string]: EndpointConfig }>
  ) => CallableModule = ({ endpoints, baseUrl, envPrefixes }) =>
    Object.keys(endpoints).reduce((acc, endpoint) => {
      acc[endpoint] = getCallableEndpoint(
        endpoints[endpoint],
        baseUrl,
        envPrefixes
      );
      return acc;
    }, {} as CallableModule);

  const getCallableEndpoint =
    (endpoint: EndpointConfig, baseUrl: string, envPrefixes: EnvPrefixes) =>
    (
      {
        uriParams = {},
        queryParams = {},
        data = {},
        options = {},
      } = {} as EndpointParams
    ) =>
      request({
        queryParams,
        uriParams,
        data,
        url: `${baseUrl}${envPrefixes[getEnvName()]}${endpoint.path}`,
        method: endpoint.method || HTTPMethod.GET,
        timeout: endpoint.timeout || apiDefaultTimeout,
        options,
      });

  return {
    setAuth,
    getAuth,
    isAuth,
    getEnvName,
    ...getCallableModulesEndpoints(),
  };
};

export default apiClient;

export const ApiClientContext = createContext<ApiClientType>(apiClient());
