import { call } from '@common/api';
import useApiMutation from '@common/hooks/useApiMutation';
import { showSuccessToast, showErrorToast } from '@common/services/toast';
import { IGetDeskDTO } from '@modules/hotdesk/hotdesk.types';
import { useQueryClient } from 'react-query';

export const useUpdateDesk = (
  resetDesk: () => void,
  setLocalDeskCopy: React.Dispatch<React.SetStateAction<IGetDeskDTO | null>>
) => {
  const queryClient = useQueryClient();

  const mutation = useApiMutation({
    mutationFn:
      (api) =>
      ({ id, payload }: { payload: any; id: number }) => {
        return call<IGetDeskDTO>(
          api.hotdeskAdmin.updateDesk({
            uriParams: { id },
            data: payload,
          })
        );
      },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries();
        resetDesk();
        setLocalDeskCopy(null);
        showSuccessToast('Zmiany zostały zapisane');
      },
      onError: ({ message }: { message: string }) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...mutation,
    updateDesk: mutation.mutate,
    isUpdatingDesk: mutation.isLoading,
  };
};
