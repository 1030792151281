import { pagePadding } from '../../../../styles/variables';
import CommonDivider from '@common/components/CommonDivider';
import TimePicker from '@modules/urlopia/components/TimePicker';
import NotConfirmedDaysList from '@modules/urlopia/components/presenceTabs/components/NotConfirmedDaysList';
import { hours, minutes } from '@modules/urlopia/helpers';
import Confirmation from '@modules/urlopia/models/Confirmation';
import ConfirmationPayload from '@modules/urlopia/models/ConfirmationPayload';
import Holiday from '@modules/urlopia/models/Holiday';
import Vacations from '@modules/urlopia/models/Vacations';
import WorkingHours from '@modules/urlopia/models/WorkingHours';
import WorkingTime from '@modules/urlopia/models/WorkingTime';
import isHoliday from '@modules/urlopia/utils/isHoliday';
import isVacationDay from '@modules/urlopia/utils/isVacationDay';
import isWorkingDay from '@modules/urlopia/utils/isWorkingDay';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, IconButton, List, Portal } from 'react-native-paper';

type PresenceTabProps = {
  userId: number;
  confirmations: Confirmation[];
  holidays: Holiday[];
  vacations: Vacations;
  onConfirm: (payload: ConfirmationPayload) => void;
  workingHours: WorkingHours;
};

const PresenceTab: React.FC<PresenceTabProps> = ({
  userId,
  confirmations = [],
  holidays = [],
  vacations = {},
  onConfirm,
  workingHours,
}) => {
  const format = 'YYYY-MM-DD';

  const [time, setTime] = useState<WorkingTime | undefined>();
  const [showTimePicker, setShowTimePicker] = React.useState(false);
  const today = moment();

  const todayAlreadyConfirmed = confirmations.some(
    (item: Confirmation) => item.date === today.format(format)
  );

  const disableConfirmPresenceButton =
    todayAlreadyConfirmed ||
    isHoliday(today, holidays) ||
    isVacationDay(today, vacations) ||
    !isWorkingDay(today, workingHours);

  useEffect(() => {
    const loadSavedTime = async () => {
      try {
        const jsonValue = await AsyncStorage.getItem('@working_time');
        if (jsonValue) {
          setTime(JSON.parse(jsonValue));
        } else {
          setTime({
            startHour: 8,
            startMinute: 0,
            endHour: 16,
            endMinute: 0,
          });
        }
      } catch (e) {}
    };
    loadSavedTime();
  }, []);

  if (!time) return null;
  const { startHour, startMinute, endHour, endMinute } = time;

  const confirmPresence = (day: string) => {
    onConfirm({
      userId,
      startTime: `${hours[startHour]}:${minutes[startMinute]}`,
      endTime: `${hours[endHour]}:${minutes[endMinute]}`,
      date: day,
    });
  };

  const handleSetTime = async (data: WorkingTime) => {
    setTime(data);
    try {
      // save time
      await AsyncStorage.setItem('@working_time', JSON.stringify(data));
    } catch (e) {}
  };

  const getCurrentMonthWorkingDays = () => {
    const days = [];

    // user can confirm presence max 2 weeks ago
    const startingDate = moment().subtract(2, 'weeks');

    while (startingDate < today) {
      if (
        !isHoliday(startingDate, holidays) &&
        !isVacationDay(startingDate, vacations) &&
        isWorkingDay(startingDate, workingHours)
      ) {
        days.push(startingDate.format(format));
      }
      startingDate.add('1', 'day');
    }
    return days;
  };

  const getNotPresentedDays = () => {
    return getCurrentMonthWorkingDays().filter((item) => {
      const alreadyConfirmed = confirmations.some(
        (confirmation: Confirmation) => {
          return confirmation.date === item;
        }
      );
      const userOnVacation = vacations.hasOwnProperty(item);
      return !userOnVacation && !alreadyConfirmed;
    });
  };

  return (
    <View>
      <List.Section>
        <List.Item
          title='Godziny pracy'
          description={`${hours[startHour]}:${minutes[startMinute]} - ${hours[endHour]}:${minutes[endMinute]}`}
          accessibilityLabel='WorkingHours'
          right={(props) => (
            <IconButton
              accessibilityLabel='EditWorkingHoursButton'
              {...props}
              icon='pencil'
              size={20}
              disabled={disableConfirmPresenceButton}
              onPress={() => setShowTimePicker(true)}
              pointerEvents='auto'
            />
          )}
        />
      </List.Section>
      <Button
        accessibilityLabel='ReportPresence'
        style={styles.button}
        mode='contained'
        disabled={disableConfirmPresenceButton}
        onPress={() => confirmPresence(today.format(format))}
      >
        {todayAlreadyConfirmed
          ? 'Zgłosiłeś już obecność na ten dzień'
          : 'Zgłoś obecność'}
      </Button>
      <CommonDivider />
      <NotConfirmedDaysList
        daysToConfirm={getNotPresentedDays()}
        confirmPresence={confirmPresence}
      />
      <Portal>
        <TimePicker
          visible={showTimePicker}
          time={time}
          onSelect={(data: WorkingTime) => handleSetTime(data)}
          onClose={() => setShowTimePicker(false)}
        />
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: pagePadding,
    marginBottom: pagePadding,
  },
  icon: {
    padding: 0,
    borderRadius: 0,
    width: 20,
    height: 20,
  },
});

export default PresenceTab;
