import { pagePadding } from '../../styles/variables';
import { StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';

function CommonDivider() {
  return <Divider style={styles.divider} />;
}

const styles = StyleSheet.create({
  divider: {
    marginBottom: pagePadding,
  },
});

export default CommonDivider;
