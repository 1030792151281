import { pagePadding } from '../../styles/variables';
import Screen from '@common/components/Screen';
import useIsMemberOf from '@common/hooks/useIsMemberOf';
import { themeModeState } from '@common/state/atoms';
import { modules } from '@config';
import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { Text, useTheme } from 'react-native-paper';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { Pressable } from 'react-native-web';
import { useRecoilValue } from 'recoil';

function Home({ navigation }) {
  const [, setVisible] = useState(false);
  const themeMode = useRecoilValue(themeModeState);
  const theme = useTheme();

  const isMemberOf = useIsMemberOf();

  const { onSecondary, onPrimary, onSecondaryContainer, primary } =
    theme.colors;

  useFocusEffect(
    useCallback(() => {
      setVisible(true);
      return () => {
        setVisible(false);
      };
    }, [])
  );

  const getModuleAction = (modulePath, disabled) => {
    if (disabled) return null;
    navigation.navigate(modulePath);
  };

  const color = themeMode === 'dark' ? onSecondaryContainer : onPrimary;

  const hasAccessToModule = (requiredRoles) => {
    if (!requiredRoles) {
      return true;
    }

    return requiredRoles.some((role) => isMemberOf(role));
  };

  return (
    <>
      <Screen loading={null}>
        <SafeAreaView style={styles.container}>
          <View style={styles.grid}>
            {Object.keys(modules).map((key, index) => {
              if (
                !modules[key].web ||
                !hasAccessToModule(modules[key].requiredRole)
              )
                return null;

              return (
                <Animatable.View
                  animation={'fadeIn'}
                  style={styles.wrapper}
                  key={key}
                >
                  <Pressable
                    onPress={() =>
                      getModuleAction(modules[key].name, modules[key].disabled)
                    }
                    style={[
                      styles.button,
                      {
                        backgroundColor:
                          themeMode === 'dark' ? onSecondary : primary,
                      },
                      modules[key].disabled && styles.disabled,
                    ]}
                  >
                    <Icon name={modules[key].icon} size={40} color={color} />
                    <Text style={[styles.label, { color }]}>
                      {modules[key].name}
                    </Text>
                  </Pressable>
                </Animatable.View>
              );
            })}
          </View>
        </SafeAreaView>
      </Screen>
    </>
  );
}

const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...Platform.select({
      web: {
        justifyContent: 'left',
      },
      default: {
        justifyContent: 'space-between',
      },
    }),
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10,
    marginRight: '20px',
    marginBottom: pagePadding,
  },
  button: {
    width: '200px',
    height: '200px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledLabelContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
  },
  disabledLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingVertical: 8,
  },
  label: {
    fontSize: 14,
    marginTop: 16,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  versionNumber: {
    textAlign: 'center',
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
    margin: 'auto',
    opacity: 0.3,
  },
});

export default Home;
