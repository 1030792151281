import Menu from '../models/Menu';
import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { IconButton, Text } from 'react-native-paper';

type MenuPicker = {
  menus: Menu[];
  index: number;
  next: () => void;
  prev: () => void;
};

const MenuPicker: React.FC<MenuPicker> = ({ menus, index, next, prev }) => {
  return (
    <View style={styles.container}>
      <IconButton
        disabled={index === 0}
        borderless
        icon='chevron-left'
        onPress={() => prev()}
      />
      <Text style={styles.label}>
        {menus[index] && moment(menus[index]?.date).format('D MMMM (dddd)')}
      </Text>
      <IconButton
        disabled={index + 1 >= menus.length}
        borderless
        icon='chevron-right'
        onPress={() => next()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 25,
  },
  label: {
    textAlign: 'center',
    minWidth: 165,
  },
});

export default MenuPicker;
