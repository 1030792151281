//The same as all the other files, move logic from api.js to here
import { IFindDriversDTO } from './parking.types';
import { call } from '@common/api';
import useApiMutation from '@common/hooks/useApiMutation';
import useApiQuery from '@common/hooks/useApiQuery';
import { ADGroups } from '@common/hooks/useIsMemberOf';
import { showSuccessToast, showErrorToast } from '@common/services/toast';
import { Buffer } from 'buffer';

enum PARKING_QUERY_KEYS {
  PARKING_IMAGE = 'parking-image',
  DRIVERS = 'drivers',
}

export const useGetPhoto = () => {
  const query = useApiQuery({
    queryKey: [PARKING_QUERY_KEYS.PARKING_IMAGE],
    queryFn: (api) => async () => {
      const response = await api.parking.photo({
        options: { responseType: 'arraybuffer' },
      });

      return Buffer.from(response.data, 'binary').toString('base64');
    },
  });

  return {
    ...query,
    photo: query.data,
    isPhotoLoading: query.isLoading,
  };
};

export const useFindDrivers = () => {
  const query = useApiQuery({
    queryKey: [PARKING_QUERY_KEYS.DRIVERS],
    queryFn: (api) => async () =>
      call<IFindDriversDTO>(api.parking.findDrivers()),
  });

  return {
    ...query,
    drivers: query.data,
    isDriversLoading: query.isLoading,
  };
};

export const useOpenTheGate = () => {
  const query = useApiMutation({
    mutationFn: (api) => async () => {
      if (api.getAuth()?.isMemberOf(ADGroups.MOBILE_TESTER)) {
        return Promise.resolve({});
      }
      return call(api.parking.open());
    },
    options: {
      onSuccess: () => {
        showSuccessToast('Żądanie otwarcia bramy wysłano pomyślnie!');
      },
      onError: ({ message }: { message: string }) => {
        showErrorToast(message);
      },
    },
  });

  return {
    ...query,
    isOpening: query.isLoading,
  };
};
