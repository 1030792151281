import { hours, minutes } from '../helpers';
import WorkingTime from '../models/WorkingTime';
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Button, IconButton, Dialog } from 'react-native-paper';

type TimePickerProps = {
  time: WorkingTime;
  visible: boolean;
  onSelect: (time: WorkingTime) => void;
  onClose: () => void;
};

const TimePicker: React.FC<TimePickerProps> = ({
  time,
  visible,
  onSelect,
  onClose,
}) => {
  const [startHour, setStartHour] = useState(time.startHour);
  const [startMinute, setStartMinute] = useState(time.startMinute);

  const [endHour, setEndHour] = useState(time.endHour);
  const [endMinute, setEndMinute] = useState(time.endMinute);

  const upHour = (index: number, setIndex: (index: number) => void) => {
    if (index >= 23) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const downMinute = (index: number, setIndex: (index: number) => void) => {
    if (index <= 0) {
      setIndex(5);
    } else {
      setIndex(index - 1);
    }
  };

  const upMinute = (index: number, setIndex: (index: number) => void) => {
    if (index >= 5) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const downHour = (index: number, setIndex: (index: number) => void) => {
    if (index <= 0) {
      setIndex(23);
    } else {
      setIndex(index - 1);
    }
  };

  const confirm = () => {
    onSelect({
      startHour,
      startMinute,
      endHour,
      endMinute,
    });
    onClose();
  };

  return (
    <Dialog visible={visible} onDismiss={onClose}>
      <Dialog.Title>Godziny pracy:</Dialog.Title>
      <Dialog.Content style={styles.diaglogContent}>
        <Text style={styles.helper}>od:</Text>
        <View style={styles.container}>
          <View style={styles.segment}>
            <IconButton
              accessibilityLabel='UpHourFrom'
              icon='triangle'
              size={18}
              style={styles.up}
              onPress={() => upHour(startHour, setStartHour)}
            />
            <View style={styles.labelContainer}>
              <Text variant='headlineSmall' style={styles.label}>
                {hours[startHour]}
              </Text>
            </View>
            <IconButton
              accessibilityLabel='DownHourFrom'
              icon='triangle'
              style={styles.down}
              size={18}
              onPress={() => downHour(startHour, setStartHour)}
            />
          </View>
          <View style={styles.segment}>
            <Text variant='headlineSmall'>:</Text>
          </View>
          <View style={styles.segment}>
            <IconButton
              icon='triangle'
              size={18}
              style={styles.up}
              onPress={() => upMinute(startMinute, setStartMinute)}
            />
            <View style={styles.labelContainer}>
              <Text variant='headlineSmall' style={styles.label}>
                {minutes[startMinute]}
              </Text>
            </View>

            <IconButton
              icon='triangle'
              style={styles.down}
              size={18}
              onPress={() => downMinute(startMinute, setStartMinute)}
            />
          </View>
        </View>
        <Text style={styles.helper}>do:</Text>
        <View style={styles.container}>
          <View style={styles.segment}>
            <IconButton
              accessibilityLabel='UpHourTo'
              icon='triangle'
              size={18}
              style={styles.up}
              onPress={() => upHour(endHour, setEndHour)}
            />
            <View style={styles.labelContainer}>
              <Text variant='headlineSmall' style={styles.label}>
                {hours[endHour]}
              </Text>
            </View>
            <IconButton
              accessibilityLabel='DownHourTo'
              icon='triangle'
              style={styles.down}
              size={18}
              onPress={() => downHour(endHour, setEndHour)}
            />
          </View>
          <View style={styles.segment}>
            <Text variant='headlineSmall'>:</Text>
          </View>
          <View style={styles.segment}>
            <IconButton
              icon='triangle'
              size={18}
              style={styles.up}
              onPress={() => upMinute(endMinute, setEndMinute)}
            />
            <View style={styles.labelContainer}>
              <Text variant='headlineSmall' style={styles.label}>
                {minutes[endMinute]}
              </Text>
            </View>

            <IconButton
              icon='triangle'
              style={styles.down}
              size={18}
              onPress={() => downMinute(endMinute, setEndMinute)}
            />
          </View>
        </View>
      </Dialog.Content>
      <Dialog.Actions>
        <Button textColor={'grey'} onPress={onClose}>
          Anuluj
        </Button>
        <Button onPress={confirm} accessibilityLabel='ConfirmChangedTime'>
          Zatwierdż
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  segment: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  down: {
    transform: [{ rotate: '180deg' }],
    opacity: 0.2,
    marginTop: 1,
  },
  up: {
    opacity: 0.2,
    marginBottom: 2,
  },
  labelContainer: {
    width: 40,
    paddingVertical: 2,
    borderTopWidth: 1,
    borderTopColor: '#2f6b26',
    borderBottomWidth: 1,
    borderBottomColor: '#2f6b26',
  },
  label: {
    textAlign: 'center',
  },
  diaglogContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  helper: {
    marginHorizontal: 6,
  },
});

export default TimePicker;
