import { userSessionState } from '../state/atoms';
import { useSetRecoilState } from 'recoil';

export default () => {
  const setRecoilState = useSetRecoilState(userSessionState);

  return () => {
    setRecoilState(null);
  };
};
