import useLogout from '../hooks/useLogout';
import auth, { Auth } from '../services/auth';
import { userSessionState } from '../state/atoms';
import apiClient, {
  ApiClientContext,
  ApiClientType,
} from '@common/services/apiClient';
import React from 'react';
import { useRecoilState } from 'recoil';

type ApiClientProviderProps = {
  children: JSX.Element | JSX.Element[];
};

const ApiClientProvider: React.FC<ApiClientProviderProps> = ({ children }) => {
  const [userSession, setUserSession] = useRecoilState(userSessionState);
  const logout = useLogout();
  const authentication: Auth | null = userSession
    ? auth({ userSession, setUserSession, logout })
    : null;
  const api: ApiClientType = apiClient(authentication);

  return (
    <ApiClientContext.Provider value={api}>
      {children}
    </ApiClientContext.Provider>
  );
};

export default ApiClientProvider;
