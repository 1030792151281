import { pagePadding } from '../../../styles/variables';
import Dish from '../models/Dish';
import { cartItemsState } from '../state/atoms';
import { getAmountString } from '../utils/currency';
import { themeModeState } from '@common/state/atoms';
import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { IconButton, Surface, Text, useTheme } from 'react-native-paper';
import { useRecoilState, useRecoilValue } from 'recoil';

type MenuItemProps = {
  item: Dish;
  length: number;
  index: number;
  onAddToCart: (item: Dish) => void;
};

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  length,
  index,
  onAddToCart,
}) => {
  const cartItems = useRecoilValue(cartItemsState);

  const { name, description, price } = item;
  const last = length === index + 1;
  const theme = useTheme();
  const { primary } = theme.colors;

  const isInCart = cartItems.some((cartItem) => cartItem.id === item.id);

  return (
    <Surface
      style={[styles.item, last && styles.noGap]}
      elevation={isInCart ? 5 : 1}
    >
      <TouchableOpacity onPress={() => onAddToCart(item)}>
        <View>
          <Text style={styles.name} variant='titleSmall'>
            {name}
          </Text>
          <Text>{description.replace(/\s\s+/g, ' ')}.</Text>
        </View>
        <View style={styles.panel}>
          <Text style={[styles.price, { color: primary }]}>
            {getAmountString(price)}
          </Text>
          <IconButton style={styles.icon} icon='plus' />
        </View>
      </TouchableOpacity>
    </Surface>
  );
};

const styles = StyleSheet.create({
  item: {
    borderRadius: 8,
    marginBottom: pagePadding,
    padding: 12,
  },
  noGap: {
    marginBottom: 0,
  },
  name: {
    marginBottom: 6,
  },
  panel: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: 21,
    height: 21,
  },
  price: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 13,
  },
});

export default MenuItem;
