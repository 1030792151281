import { pagePadding } from '../../../styles/variables';
import { useGetProviders } from '../jajemto.hooks';
import Screen from '@common/components/Screen';
import { themeModeState } from '@common/state/atoms';
import { getBoxSize } from '@common/utils/ui';
import NoMenu from '@modules/jajemto/components/NoMenu';
import { ParamListBase } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack/src/types';
import React, { useEffect, useMemo } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { Text, useTheme } from 'react-native-paper';
import { useRecoilValue } from 'recoil';

type Props = StackScreenProps<ParamListBase>;

const ProvidersScreen = ({ navigation }: Props) => {
  const { providers, isLoadingProviders, isError } = useGetProviders();
  const themeMode = useRecoilValue(themeModeState);
  const theme = useTheme();
  const { onSecondary, primary } = theme.colors;

  const availableProviders = useMemo(
    () =>
      providers?.filter((provider) => provider.enabled && !provider.disabled) ||
      [],
    [providers]
  );

  useEffect(() => {
    if (availableProviders.length === 1) {
      navigation.navigate('MenuScreen', {
        screen: 'MenuScreen',
        params: { providerId: availableProviders[0].id },
      });
    }
  }, [availableProviders]);

  return (
    <Screen loading={isLoadingProviders} isError={isError}>
      <View style={styles.grid}>
        {availableProviders.length === 0 && <NoMenu />}
        {availableProviders.map((provider, index) => {
          return (
            <Animatable.View
              animation={'fadeIn'}
              style={styles.wrapper}
              delay={index * 200}
              key={provider.short_name}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('MenuScreen', {
                    screen: 'MenuScreen',
                    params: { providerId: provider.id },
                  });
                }}
                accessibilityLabel={provider.short_name}
                style={[
                  styles.button,
                  {
                    backgroundColor:
                      themeMode === 'dark' ? onSecondary : primary,
                  },
                ]}
              >
                <Text>{provider.full_name}</Text>
              </TouchableOpacity>
            </Animatable.View>
          );
        })}
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10,
    marginBottom: pagePadding,
  },
  button: {
    width: getBoxSize(),
    height: getBoxSize(),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default ProvidersScreen;
