import { ApiClientContext, ApiClientType } from '../services/apiClient';
import { useContext } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import { MutationFunction } from 'react-query/types/core/types';

interface UseMutationParams<TData, TError, TVariables, TContext> {
  mutationFn: (apiClient: ApiClientType) => MutationFunction<TData, TVariables>;
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationFn'
  >;
}

export default <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>({
  mutationFn,
  options,
}: UseMutationParams<TData, TError, TVariables, TContext>) => {
  const api = useContext(ApiClientContext);

  return useMutation(mutationFn(api), options);
};
