import { pagePadding } from '../../styles/variables';
import { Dimensions } from 'react-native';

export const getBoxSize = () => {
  const windowWidth = Dimensions.get('window').width;
  const itemsPerRow = 2;
  const gridGap = pagePadding * (itemsPerRow - 1);

  return (windowWidth - (2 * pagePadding + gridGap)) / itemsPerRow;
};
