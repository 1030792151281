import HotdeskAdminScreen from './screens/HotdeskAdminScreen';
import Header from '@common/components/Header';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

export type HotDeskAdminParamList = {
  HotdeskAdminScreen: { selectedDay: string };
};

const Stack = createStackNavigator<HotDeskAdminParamList>();

function HotdeskAdminModule() {
  const cardStyleInterpolator =
    Platform.OS === 'android'
      ? CardStyleInterpolators.forFadeFromBottomAndroid
      : CardStyleInterpolators.forHorizontalIOS;

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='HotdeskAdminScreen'
        options={{
          header: (props) => <Header {...props} />,
          title: 'Hotdesk Admin',
          cardStyleInterpolator,
        }}
        component={HotdeskAdminScreen}
      />
    </Stack.Navigator>
  );
}

export default HotdeskAdminModule;
