import BookDeskModal from '@modules/hotdesk/components/BookDeskModal';
import { IGetDeskDTO, IGetRoomDTO } from '@modules/hotdesk/hotdesk.types';
import { Moment } from 'moment/moment';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-paper';

type InformationModalProps = {
  desk: IGetDeskDTO;
  onDismiss: () => void;
  activeRoom: IGetRoomDTO | null;
};
function InformationModal({
  desk,
  onDismiss,
  activeRoom,
}: InformationModalProps) {
  const Actions = () => <Button onPress={onDismiss}>Wyjdź</Button>;

  const Content = () => (
    <Text>
      Biurko
      <Text style={styles.bold}> {desk?.name} </Text>w sali
      <Text style={styles.bold}> {activeRoom?.name}</Text> jest na stałe
      przypisane do
      <Text style={styles.bold}>
        {' '}
        {desk?.assignedTo ? desk.assignedTo : 'nieznanego pracownika'}
      </Text>
      .
    </Text>
  );

  return (
    <BookDeskModal
      visible={!!desk}
      onDismiss={onDismiss}
      title='Informacje o biurku'
      actions={<Actions />}
    >
      {<Content />}
    </BookDeskModal>
  );
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
});

export default InformationModal;
