import { IGetEmergencyContactsDTO } from './kontakty.types';
import { call } from '@common/api';
import useApiQuery from '@common/hooks/useApiQuery';

enum KONTAKTY_QUERY_KEYS {
  GET_EMERGENCY_CONTACTS = 'getEmergencyContacts',
}

export const useGetEmergencyContacts = () => {
  const query = useApiQuery({
    queryKey: [KONTAKTY_QUERY_KEYS.GET_EMERGENCY_CONTACTS],
    queryFn: (api) => () => {
      return call<IGetEmergencyContactsDTO>(
        api.kontakty.getEmergencyContacts()
      );
    },
  });

  return {
    ...query,
    emergencyContacts: query.data,
    isEmergencyContactsLoading: query.isLoading,
  };
};
