import Settlement from '../components/Settlement';
import { useGetprofileDetails, useGetSettlements } from '../jajemto.hooks';
import Screen from '@common/components/Screen';
import React from 'react';

const SettlementScreen = () => {
  const { settlements, isLoadingSettlements, isSettlementsError } =
    useGetSettlements();
  const { profileDetails, isLoadingProfile, isProfileError } =
    useGetprofileDetails();

  return (
    <Screen
      loading={isLoadingSettlements || isLoadingProfile}
      isError={isProfileError || isSettlementsError}
    >
      <Settlement
        settlements={settlements?.settlements ?? []}
        profile={profileDetails}
      />
    </Screen>
  );
};

export default SettlementScreen;
