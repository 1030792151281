import moment from 'moment';
import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import {
  IconButton,
  List,
  Subheading,
  Text,
  useTheme,
} from 'react-native-paper';

type NotConfirmedDaysListProps = {
  daysToConfirm: string[];
  confirmPresence: (day: string) => void;
};

const NotConfirmedDaysList: React.FC<NotConfirmedDaysListProps> = ({
  daysToConfirm,
  confirmPresence,
}) => {
  const theme = useTheme();
  const { primary } = theme.colors;

  return (
    <>
      <Text variant='titleMedium'>
        Braki w obecności (w ostatnich 2 tygodniach)
      </Text>
      <Subheading>
        Kliknij w ikonę{' '}
        <IconButton
          style={styles.icon}
          icon={'draw'}
          size={18}
          iconColor={primary}
        />{' '}
        aby potwierdzić obecność
      </Subheading>
      <ScrollView>
        {daysToConfirm.map((date: string) => (
          <List.Item
            key={date}
            title={`${date} (${moment(date).format('dddd')})`}
            accessibilityLabel='WorkingHours'
            right={() => (
              <IconButton
                style={styles.icon}
                icon={'draw'}
                size={20}
                iconColor={primary}
                onPress={() => confirmPresence(date)}
              />
            )}
          />
        ))}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  icon: {
    padding: 0,
    borderRadius: 0,
    width: 20,
    height: 20,
  },
});

export default NotConfirmedDaysList;
