import { ModuleConfig, OIDCConfig } from './global.types';
import hotdesk from '@modules/hotdesk/config';
import hotdeskAdmin from '@modules/hotdeskAdmin/config';
import jajemto from '@modules/jajemto/config';
import kontakty from '@modules/kontakty/config';
import parking from '@modules/parking/config';
import urlopia from '@modules/urlopia/config';

export const apiDefaultTimeout = 3000;

export enum Modules {
  URLOPIA = 'urlopia',
  PARKING = 'parking',
  JAJEMTO = 'jajemto',
  KONTAKTY = 'kontakty',
  HOTDESK = 'hotdesk',
  HOTDESK_ADMIN = 'hotdeskAdmin',
}

export type ModulesConfig = Record<Modules, ModuleConfig>;

export const modules: ModulesConfig = {
  urlopia,
  parking,
  jajemto,
  kontakty,
  hotdesk,
  hotdeskAdmin,
};

export const oidc: OIDCConfig = {
  autoDiscovery:
    'https://login.microsoftonline.com/09a1625a-533f-428a-b137-b8d1f46d688a/v2.0',
  tokenEndpoint:
    'https://login.microsoftonline.com/09a1625a-533f-428a-b137-b8d1f46d688a/oauth2/v2.0/token',
  remoteLogoutEndpoint:
    'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
  fingoScheme: 'msauth.com.fingo.ios-app',
  clientId: '136f665b-8ad9-4662-92b6-3aad6f959e0f',
  scopes: [
    'openid',
    'profile',
    'email',
    'offline_access',
    'api://136f665b-8ad9-4662-92b6-3aad6f959e0f/sso_access',
  ],
};

export const linking = {
  config: {
    screens: {
      Home: '',
      ...modules.hotdesk.routingScreens,
      ...modules.jajemto.routingScreens,
      ...modules.kontakty.routingScreens,
      ...modules.parking.routingScreens,
      ...modules.urlopia.routingScreens,
      ...modules.hotdeskAdmin.routingScreens,
      Login: 'login',
      SettingsScreen: 'settings',
    },
  },
  prefixes: [],
};

export default {
  oidc,
  modules,
  apiDefaultTimeout,
};
