import { errors } from '@modules/urlopia/helpers';

export const call = async <T = any>(request: any) => {
  try {
    const { data } = await request;
    return data as T;
  } catch (error: any) {
    if (
      error?.response?.data?.message &&
      error.response.data.message in errors
    ) {
      // @ts-ignore
      throw new Error(errors[error.response.data.message]);
    }

    if (error?.response?.data?.message) {
      throw new Error(error.response.data.message);
    }

    if (error?.response?.data?.error) {
      throw new Error(error.response.data.error);
    }

    throw new Error(error);
  }
};
