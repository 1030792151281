import PresenceConfirmation from '../components/PresenceConfirmation';
import Screen from '@common/components/Screen';
import {
  useConfirmPresence,
  useGetAbsentUsersAtDate,
  useGetHolidays,
  useGetPresenceConfirmations,
  useGetVacations,
  useGetWorkingHours,
} from '@modules/urlopia/urlopia.hooks';
import moment from 'moment';
import React, { useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useIsFetching } from 'react-query';

interface Props {
  ec: boolean;
  userId: number;
}

export default function PresenceConfirmationScreen({ ec, userId }: Props) {
  const loading = useIsFetching();
  const isEC = !!ec;

  const [selectedTab, setSelectedTab] = useState<string>(
    isEC ? 'presence' : 'absent'
  );

  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format('Y-MM-DD')
  );

  const { confirmations } = useGetPresenceConfirmations(userId);

  const { holidays } = useGetHolidays();

  const { vacations } = useGetVacations(userId);

  const { absentUsers } = useGetAbsentUsersAtDate(selectedDate);
  const { workingHours, isLoadingWorkingHours } = useGetWorkingHours();

  const { confirmPresence } = useConfirmPresence();

  return (
    <Screen loading={!!loading || isLoadingWorkingHours}>
      <ScrollView accessibilityLabel='KontaktyPage'>
        <PresenceConfirmation
          selectedTab={selectedTab}
          handleSelectedTab={(tab: string) => setSelectedTab(tab)}
          confirmations={confirmations}
          holidays={holidays}
          vacations={vacations?.usersVacations}
          onConfirm={confirmPresence}
          userId={userId}
          absentUsers={absentUsers}
          selectedDate={selectedDate}
          handleSelectDate={(date: string) => setSelectedDate(date)}
          isEC={isEC}
          workingHours={workingHours}
        />
      </ScrollView>
    </Screen>
  );
}
